import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 通过订单或者机构编码获取经纪公司信息
   * @param params
   * @returns {*} BROKERAGE.fetchGetBrokerageInfo
   */
  fetchGetBrokerageInfo (params) {
    return axios.get(`${SYS_APP_PATH}/brokerage/find`, { params })
      .then(res => res.data)
  }
}
