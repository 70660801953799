import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 我的信息模块查看个人信息
   * @param params
   * @returns {*} USER.fetchUserViewList
   */
  fetchUserViewList (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/view`, data, { params }).then(res => res.data)
  },
  /**
   * 更新用户信息
   * @param params
   * @returns {*} USER.fetchUserUpdate
   */
  fetchUserUpdate (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/update`, data, { params }).then(res => res.data)
  },
  /**
   * 生成智能验证码
   * @param params
   * @param data
   * @returns {*} USER.fetchUserGenVerifyImage
   */
  fetchUserGenVerifyImage () {
    return `${BASE_APP_PATH}/getKaptchaImage?t=${new Date().getTime()}`
  },
  /**
   * 发送短信验证码
   * @param params
   * @param data
   * @returns {*} USER.fetchUserSendSmsCode
   */
  fetchUserSendSmsCode (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/sendSmsCode`, data, { params }).then(res => res.data)
  },
  /**
   * 用户注册
   * @param params
   * @param data
   * @returns {*} USER.fetchUserRegister
   */
  fetchUserRegister (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/register`, data, { params }).then(res => res.data)
  }
}
