import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 租户平台配置产品列表
   * @param params
   * @returns {*} $sysappApi.TENANT.fetchGetTenantBusinessProduct
   */
  fetchGetTenantBusinessProduct (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/product/business/product`, { params }).then(res => res.data)
  }
}
