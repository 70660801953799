<template>
  <el-aside class="gtm-aside" :width="collapsed ? '72px' : width" :class="{ 'gtm-aside--collapse': collapsed }">
    <slot></slot>
    <div class="gtm-aside-trigger" @click="handleTrigger">
      <i class="gtm-aside-trigger-icon" :class="{ 'el-icon-s-fold': !collapsed, 'el-icon-s-unfold': collapsed }"></i>
    </div>
  </el-aside>
</template>

<script>
export default {
  name: 'GtmAside',
  props: {
    width: {
      type: String,
      default: '240px'
    }
  },
  data () {
    return {
      collapsed: false
    }
  },
  created () {
    this.checkWidth()
  },
  methods: {
    checkWidth () {
      if (window.innerWidth <= 1440) {
        this.collapsed = true
      }
    },
    handleTrigger () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>
