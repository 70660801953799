<!--
  @name: 快捷指令浮窗
  @description:
  @author: 马群
  @time: 2024-04-22 10:14:10
-->
<template>
  <div class="gtm-quick-actions">
    <div class="rightFunctionButton">
      <div class="all">
        <div class="verifyService" @click="showGuaranteeVerify = true">
          <i class="gtm-iconfont icon-baohanyanzhen tool-icon"></i>
          <div>保函验真</div>
        </div>
        <div class="phoneService">
          <i class="gtm-iconfont icon-kefudianhua tool-icon"></i>
          <div>客服电话</div>
          <div class="telephone">
            <div class="picture">
              <div>15011348980</div>
              <div class="phone-desc">7X24小时人工客服在线</div>
            </div>
          </div>
        </div>
        <div class="consultService" @click="onConsultClick">
          <i class="gtm-iconfont icon-lijizixun tool-icon"></i>
          <div>立即咨询</div>
        </div>
        <el-tooltip class="item" effect="dark" :content="isCollapse ? '展开' : '收起'" placement="left">
          <div class="collapse" @click="onCollapseClick">
            <i v-if="!isCollapse" class="gtm-iconfont icon-zhankai" style="font-size: 13px"></i>
            <i
              v-if="isCollapse"
              class="gtm-iconfont icon-zhankai"
              style="font-size: 13px; transform: rotate(180deg)"
            ></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <GtmGuaranteeVerify :visible.sync="showGuaranteeVerify"></GtmGuaranteeVerify>
    <!-- 立即咨询弹框 -->
    <GtmConsultDialog :visible.sync="showConsultDialog"/>
  </div>
</template>

<script>
// import { rootMethods } from '@gtm-butils'
import GtmConsultDialog from '../gtm-consult-dialog/GtmConsultDialog'
import GtmGuaranteeVerify from '../gtm-guarantee-verify/GtmGuaranteeVerify'

export default {
  name: 'GtmQuickActions',
  components: {
    GtmConsultDialog,
    GtmGuaranteeVerify
  },
  data () {
    return {
      isCollapse: false,
      showConsultDialog: false,
      showGuaranteeVerify: false
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    // 点击立即申请，跳转到保函申请页面
    // onGoToApply (productCode) {
    //   let routeInfo = {
    //     appName: 'guarantee-web',
    //     path: '/institutionList',
    //     query: {
    //       productCode
    //     }
    //   }
    //   rootMethods.TOOLS.qiankunRouterPush(routeInfo)
    // },
    onConsultClick () {
      this.showConsultDialog = true
    },
    onCollapseClick () {
      let rightFunctionButton = document.getElementsByClassName('rightFunctionButton')
      let applyRightNow = document.getElementsByClassName('applyRightNow')
      let verifyService = document.getElementsByClassName('verifyService')
      let phoneService = document.getElementsByClassName('phoneService')
      let consultService = document.getElementsByClassName('consultService')
      let collapse = document.getElementsByClassName('collapse')
      if (!this.isCollapse) {
        if (applyRightNow[0]) {
          applyRightNow[0].style.display = 'none'
        }
        rightFunctionButton[0].style.bottom = '1.25rem'
        verifyService[0].style.display = 'none'
        phoneService[0].style.display = 'none'
        consultService[0].style.display = 'none'
        collapse[0].style.borderRadius = '3.125rem'
        collapse[0].style.height = '4.375rem'
        collapse[0].style.width = '4.375rem'
        this.isCollapse = !this.isCollapse
      } else {
        if (applyRightNow[0]) {
          applyRightNow[0].style.display = 'flex'
        }
        rightFunctionButton[0].style.bottom = '1.25rem'
        verifyService[0].style.display = 'flex'
        phoneService[0].style.display = 'flex'
        consultService[0].style.display = 'flex'
        collapse[0].style.height = '4.375rem'
        collapse[0].style.width = '4.375rem'
        collapse[0].style.borderRadius = '0px 0px 6.25rem 6.25rem'
        this.isCollapse = !this.isCollapse
      }
    }
  }
}
</script>
<style lang="scss">
.gtm-quick-actions {
  width: 100%;

  .rightFunctionButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 1000;
    width: 70px;

    @mixin hoverClass {
      color: #165dff;
      cursor: pointer;
    }
    @mixin flexClass {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fff;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #666666;
      //line-height: 16px;
    }

    .tool-icon {
      font-size: 28px;
    }

    .VerificationTrue {
      @include flexClass();
      box-shadow: 0px 1px 6px 0px rgba(109, 109, 109, 0.2);

      &:hover {
        @include hoverClass();
      }
    }

    .applyRightNow {
      @include flexClass();
      background: linear-gradient(180deg, #1890ff 0%, #35c3ff 100%) !important;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      margin-bottom: 10px;
      font-size: 14px;
      color: #ffffff !important;
      // width: 80px;
      height: 70px;
      cursor: pointer;
      transition: all 0.3s;
      animation: homeScreenMove 2s linear infinite;
      // &:hover .first{
      //   transform:scale(1.2); /*将元素放大2倍*/
      //   transition: all 0.3s;
      //   }
      .first {
        width: 29px;
        height: 28px;
      }
    }

    @keyframes homeScreenMove {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
      100% {
        transform: translateY(0);
      }
    }

    .all {
      // box-shadow: 0px 1px 6px 0px rgba(109, 109, 109, 0.2);
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      // width: 70px;
      // margin-top: 10px;
      .verifyService {
        height: 75px;
        border-radius: 100px 100px 0px 0px;
        @include flexClass();
        justify-content: flex-end;
        padding-bottom: 10px;

        .verify-img {
          width: 28px;
          height: 28px;
          margin-top: 10px;
        }

        .first {
          display: block;
        }

        .hover {
          display: none;
        }

        &:hover {
          @include hoverClass();

          .first {
            display: none;
          }

          .hover {
            display: block;
          }
        }
      }

      .phoneService {
        height: 75px;
        transition: all 0.3s;
        @include flexClass();
        position: relative;

        .first {
          display: block;
        }

        .phone-img {
          width: 28px;
          height: 28px;
        }

        .hover {
          display: none;
        }

        .telephone {
          display: none;
          position: absolute;
          top: 0;
          right: 85px;
          width: 188px;
          height: 76px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
          transition: all 0.6s;
          z-index: -1;

          &::after {
            content: '';
            width: 0;
            height: 0;
            border: 5px solid;
            border-color: transparent transparent transparent white;
            position: absolute;
            top: 34.5px;
            left: 188px;
          }

          .picture {
            height: 76px;
            background: #ffffff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
            width: 188px;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #165dff;
            box-sizing: border-box;
            padding: 20px;
            border-radius: 2px;

            .phone-desc {
              margin-top: 10px;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #999999;
              line-height: 12px;
            }

            //  div{
            //   padding: 29px 50px 40px;
            //  }
          }
        }

        &:hover {
          @include hoverClass();

          .first {
            display: none;
          }

          .hover {
            display: block;
          }

          .telephone {
            display: block;
          }
        }
      }

      .consultService {
        height: 75px;
        border-radius: 0px 0px 0px 2px;
        transition: all 0.3s;
        @include flexClass();

        .consult-img {
          width: 28px;
          height: 28px;
        }

        .first {
          display: block;
        }

        .hover {
          display: none;
        }

        &:hover {
          @include hoverClass();

          .first {
            display: none;
          }

          .hover {
            display: block;
          }
        }
      }

      .collapse {
        height: 60px;
        border-radius: 0px 0px 100px 100px;
        cursor: pointer;
        @include flexClass();

        .collapse-img {
          width: 13px;
          height: 12px;
        }

        .first {
          display: block;
        }

        .hover {
          display: none;
        }

        &:hover {
          @include hoverClass();

          .first {
            display: none;
          }

          .hover {
            display: block;
          }
        }
      }
    }
  }
}

.apply-tooltip {
  width: 178px;
  height: 88px;
  padding: 0px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

  .apply-content {
    height: 56px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding-top: 16px;

    .apply-btn {
      position: relative;
      height: 28px;
      line-height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #333333;
      font-style: normal;
      text-align: left;
      margin-left: 36px;
      cursor: pointer;

      .circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: 12px;
      }

      .circle--tender {
        background-color: #165dff;
      }

      .circle--performance {
        background-color: #00bcfa;
      }
    }

    .apply-btn:hover {
      color: #165dff;
    }

    //.apply-btn:first-child::before {
    //  content: '';
    //  width: 6px;
    //  height: 6px;
    //  background-color: #165DFF;
    //  border-radius: 50%
    //}
  }
}
</style>
