<!--
  @name: 表格分页布局组件
  @description:
  @author: 马群
  @time: 2023-12-25 13:48:44
-->
<template>
  <div class="tablePageComponent">
    <div class="tableBox">
      <slot></slot>
    </div>
    <div class="paginationBox" :class="pagePositionClass" v-if="showPagination">
      <el-pagination
        :background="isHaveBackground"
        type="manual"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageInfo.currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size.sync="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GtmLayoutPagination',
  components: {},
  props: {
    page: {
      type: Object,
      default: () => {
        return {
          pageSize: 10,
          currentPage: 1,
          total: 0
        }
      }
    },
    pagePosition: {
      type: String,
      default: 'center'
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    isHaveBackground: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      pageInfo: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {
    pagePositionClass () {
      let map = {
        center: 'page-position-center',
        right: 'page-position-right',
        left: 'page-position-left'
      }
      return map[this.pagePosition]
    }
  },
  watch: {
    // 本来不想写watch的，但是这个组件有点特殊，暂时就用watch了，真的low
    // 这里是生命周期的起点，当数据改变时，就会触发pageInfo的改变
    page: {
      deep: true,
      handler (val) {
        this.pageInfo = val
      }
    }
  },
  methods: {
    pageChange () {
      this.$emit('pageChange', this.pageInfo)
    },
    // 每次显示条数改变
    handleSizeChange (val) {
      this.$emit('size-change', val)
      this.pageChange()
    },
    // 当前页数改变
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val // 注意 同一个页面中如果有多个 会造成其他翻页组件的值也会改变(如果用的是同一份数据的话)
      this.$emit('current-change', val)
      this.pageChange()
    }
  }
}
</script>
<style lang="scss">
.tablePageComponent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  .tableBox {
    flex: 1;
    height: 0;
    // height: 100%;
    overflow: hidden;
    position: relative;

    > .el-table {
      position: absolute;
      // width: 100%;
    }
  }

  .paginationBox {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-position-center {
    justify-content: center;
  }

  .page-position-right {
    justify-content: right;
  }

  .page-position-left {
    justify-content: left;
  }
}
</style>
