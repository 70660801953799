<template>
  <div class="gtm-micro-app__page">
    <div class="gtm-micro-app__bi" id="bi"></div>
  </div>
</template>

<script>
import { loadMicroApp } from 'qiankun'
import { mapState } from 'vuex'

export default {
  name: 'Bi',
  props: {},
  data () {
    return {
      microApp: null,
      microAppRender: false
    }
  },
  computed: {
    ...mapState(['rootMicroAppData'])
  },
  mounted () {
    if (this.$store.state.activeMicroApp.instance && this.$store.state.activeMicroApp.appName === 'bi') {
      this.$store.state.activeMicroApp.instance.unmount()
    }
    this.microApp = loadMicroApp({
      name: 'bi',
      entry: `${window.location.origin}/bi`,
      container: '#bi',
      activeRule: '/gtm/web/platform/bi',
      props: { data: this.rootMicroAppData }
    })
    this.$store.state.activeMicroApp = {
      instance: this.microApp,
      appName: 'bi'
    }
    console.log('【base-platform】【bi】子应用执行mounted方法')
  },
  beforeRouteLeave (to, from, next) {
    if (this.microApp !== null) {
      this.microApp.unmount()
    }
    next()
  },
  methods: {}
}
</script>

<style lang="scss">
.gtm-micro-app__page {
  width: 100%;
  height: 100%;

  .gtm-micro-app__bi {
    height: 100%;
  }
}
</style>
