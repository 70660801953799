import axios from 'axios'

export default {
  /**
   * 获取平台基础用户数据
   * @param params
   * @returns {*} PORTAL.fetchPortalLoginOut
   */
  fetchPortalLoginOut (path, params) {
    return axios.get(`${path}/api/portal/v4/logout`, { params, withCredentials: true }).then(res => res.data)
  }
}
