
export const initCityData = (optionsCity) => {
  let arr = JSON.parse(JSON.stringify(optionsCity))
  arr.forEach(item => {
    let DirectlyGovArr = ['110000', '120000', '310000', '500000']
    if (DirectlyGovArr.indexOf(item.value) === -1) {
      // 不是直辖市的省份加省本级
      changeOriginDataShift(item, { label: '省本级', value: item.value }) // 追加省本级
    } else {
      item.children = [] // 是直辖市的时候把市辖区去掉 追加成当前直辖市
      changeOriginDataShift(item, { label: item.label, value: item.value })
    }
    if (item.value === '140000') {
      changeOriginData(item, { label: '山西转型综合改革示范区', value: '140171' }) // 山西 追加的山西转型综合改革示范区
    } else if (item.value === '350000') {
      changeOriginData(item, { label: '平潭县', value: '350128' }) /// /福建省 追加平潭县
    } else if (item.value === '210000') {
      changeOriginData(item, { label: '沈抚示范区', value: 'sfsfq' }) // 辽宁省 追加沈抚示范区
    } else if (item.value === '610000') {
      // this.changeOriginData(item,{ label : "西咸新区", value : "xxxq" });// 陕西的西咸新区
      // this.changeOriginData(item,{ label : "杨凌示范区", value : "ylsfq" });// 陕西的杨凌示范区
      let obj = {
        xxxq: '西咸新区',
        ylsfq: '杨凌示范区'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '220000') {
      changeOriginData(item, { label: '长白山保护开发区', value: 'cbsbhkfq' }) // 吉林省 长白山保护开发区
    } else if (item.value === '220000') {
      changeOriginData(item, { label: '长白山保护开发区', value: 'cbsbhkfq' }) // 吉林省 长白山保护开发区
    } else if (item.value === '460000') {
      // 海南的省直辖县级行政区
      item.children = item.children.filter(item => item.value !== '469000') // 省直辖县级行政区划
      let obj = {
        469005: '文昌市',
        469021: '定安县',
        469002: '琼海市',
        469006: '万宁市',
        469022: '屯昌县',
        469023: '澄迈县',
        469024: '临高县',
        469025: '白沙黎族自治县',
        469001: '五指山市',
        469029: '保亭黎族苗族自治县',
        469028: '陵水黎族自治县',
        469027: '乐东黎族自治县',
        469007: '东方市',
        469026: '昌江黎族自治县',
        469030: '琼中黎族苗族自治县'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '420000') {
      // 湖北省省直辖县级行政区划
      let obj = {
        429006: '天门市',
        429021: '神农架林区'
      }
      item.children = item.children.filter(item => item.value !== '429000') // 省直辖县级行政区划
      changeOriginDataArr(item, obj)
    } else if (item.value === '150000') {
      // 内蒙 省直辖县级行政区划
      let obj = {
        150781: '满洲里市'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '130000') {
      // 河北省省省直辖县级行政区划
      let obj = {
        130682: '定州市',
        130181: '辛集市',
        xaxq: '雄安新区'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '630000') {
      // 青海省 省直辖县级行政区划
      let obj = {
        632801: '格尔木市'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '410000') {
      // 河南省 省直辖县级行政区划
      item.children = item.children.filter(item => item.value !== '419000') // 省直辖县级行政区划
      let obj = {
        419001: '济源市'
      }
      changeOriginDataArr(item, obj)
    } else if (item.value === '650000') {
      // 新疆维吾尔自治区 省直辖县级行政区划
      item.children = item.children.filter(item => item.value !== '659000') // 自治区直辖县级行政区划
    } else if (item.value === '500000') {
      // 新疆维吾尔自治区 省直辖县级行政区划
      item.children = item.children.filter(item => item.value !== '500200') // 县
    }
  })
  return JSON.parse(JSON.stringify(arr))
}

function changeOriginData (item, pushData) {
  let bool = item.children.some(sonD => sonD.value === pushData.value) // 没有就追加
  if (!bool) {
    item.children.push(pushData)
  }
}

function changeOriginDataShift (item, pushData) {
  let bool = item.children.some(sonD => sonD.value === pushData.value) // 没有就追加
  if (!bool) {
    item.children.unshift(pushData)
  }
}

function changeOriginDataArr (item, obj) {
  let arr = []
  for (let key in obj) {
    arr.push({ label: obj[key], value: key })
  }
  item.children.push(...arr)
}
