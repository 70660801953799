import INSTITUTION from './institution/index'
import BUSINESS from './business/index'
import PRODUCT from './product'
import INTERFACE from './interface/index'
import GO_ONLINE from './go-online/index'

export default {
  INSTITUTION,
  BUSINESS,
  INTERFACE,
  PRODUCT,
  GO_ONLINE
}
