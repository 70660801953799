<template>
  <div v-if="visible" class="gtm-mask">
    <div class="loading-box">
      <div class="circle">
        <img style="width: 100%" src="../../assets/image/gtmLoading/loading.png" alt=""/>
      </div>
      <div class="message">即将呈现，请稍后...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GtmLoading',
  props: {
    show: Boolean
  },
  computed: {
    visible: {
      get () {
        return this.show
      },
      set () {
        this.$emit('update:show', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gtm-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes rotate-clockwise {
    0% {
      transform: rotate(0deg); /* 初始状态，未旋转 */
    }
    100% {
      transform: rotate(360deg); /* 结束状态，旋转360度，完成一圈 */
    }
  }

  .loading-box {
    width: 160px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .circle {
      margin-top: 46px;
      width: 34px;
      height: 34px;
      animation: rotate-clockwise 1.6s linear infinite;
    }

    .message {
      margin-top: 12px;
      width: 124px;
      height: 22px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }
}
</style>
