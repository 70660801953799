import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 查询是否满足业务验证
   * @param applyId
   * @param params
   * @returns {any} $developmentApi.BUSINESS.fetchGetBusinessStatusByApplyId
   */
  fetchGetBusinessStatusByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/businessTest/getBusinessStatus/${applyId}`, { params }).then(res => res.data)
  },
  /**
   * 获取业务验证模版列表
   * @param params
   * @param data
   * @returns {any} $developmentApi.BUSINESS.fetchBusinessTestTemplateList
   */
  fetchBusinessTestTemplateList (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/businessTest/template/list`, data, { params }).then(res => res.data)
  },
  /**
   * 获取接口参数模版
   * @param sceneId
   * @param params
   * @returns {any} $developmentApi.BUSINESS.fetchGetInterfaceTemplateBySceneId
   */
  fetchGetInterfaceTemplateBySceneId (sceneId, params) {
    return axios
      .get(`${DEVELOPMENT_PATH}/businessTest/getInterfaceTemplate/${sceneId}`, { params })
      .then(res => res.data)
  },
  /**
   * 获取业务验证链接
   * @param sceneId
   * @param params
   * @returns {any} $developmentApi.BUSINESS.fetchGetBusinessUrlBySceneId
   */
  fetchGetBusinessUrlBySceneId (sceneId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/businessTest/getBusinessUrl/${sceneId}`, { params }).then(res => res.data)
  },
  /**
   * 业务场景验证
   * @param params
   * @param data
   * @returns {*} $developmentApi.BUSINESS.fetchUpdateBusinessTestVerify
   */
  fetchUpdateBusinessTestVerify (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/businessTest/verify`, data, { params }).then(res => res.data)
  },
  /**
   * 获取验证报告下载地址
   * @param applyId
   * @returns {*} $developmentApi.BUSINESS.fetchGetBusinessReportingByApplyId
   */
  fetchGetBusinessReportingByApplyId (applyId) {
    return axios.get(`${DEVELOPMENT_PATH}/businessTest/getBusinessReporting/${applyId}`).then(res => res.data)
  },
  /**
   * 删除用例
   * @param params
   * @returns {*} PROJECT.fetchDeleteBusinessTestBySceneId
   */
  fetchDeleteBusinessTestBySceneId (sceneId) {
    return axios.post(`${DEVELOPMENT_PATH}/businessTest/delete/${sceneId}`).then(res => res.data)
  }
}
