const developmentMenu = [
  {
    name: '机构侧页面初始页面',
    routeName: 'MerchantIndex',
    path: '/merchantIndex',
    appName: 'development',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '申请入驻',
    routeName: 'Settled',
    path: '/settled',
    appName: 'development',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '入驻成功',
    routeName: 'ApplySuccess',
    path: '/applySuccess',
    appName: 'development',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '入驻失败',
    routeName: 'ApplyFail',
    path: '/applyFail',
    appName: 'development',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '机构列表',
    routeName: 'MerchantList',
    path: '/merchantList',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构详情',
    routeName: 'MerchantDetail',
    path: '/merchantDetail',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构申请信息',
    routeName: 'MerchantApply',
    path: '/merchantApply',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构开发调试',
    routeName: 'MerchantDevelop',
    path: '/merchantDevelop',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构业务信息配置',
    routeName: 'BusinessConfig',
    path: '/businessConfig',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构场景测试验证',
    routeName: 'BusinessVerify',
    path: '/businessVerify',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '机构接口参数配置',
    routeName: 'MerchantConfig',
    path: '/merchantConfig',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '平台接口参数配置',
    routeName: 'PlatformConfig',
    path: '/platformConfig',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '申请上线',
    routeName: 'ApplyOnline',
    path: '/applyOnline',
    appName: 'development',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  }
]

export { developmentMenu }
