<template>
  <div class="gtm-divider-title">
    <div class="divider-block"></div>
    <div class="divider-label">
      <slot></slot>
    </div>
    <!--    <el-divider></el-divider>-->
  </div>
</template>

<script>
export default {
  name: 'GtmDividerTitle'
}
</script>
<style lang="scss">
.gtm-divider-title {
  position: relative;

  .divider-block {
    position: absolute;
    left: 0px;
    top: 3px;
    width: 4px;
    height: 15px;
    background-image: inherit;
    background-position: inherit;
    background-size: inherit;
    background-repeat: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: rgb(0, 132, 255);
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }

  .divider-label {
    margin-left: 10px;
  }

  .el-divider--horizontal {
    margin: 16px 0;
  }
}
</style>
