const tenderTypeList = [
  {
    value: 'A',
    label: '工程建设项目'
  },
  {
    value: 'A01',
    label: '房屋建筑'
  },
  {
    value: 'A02',
    label: '市政'
  },
  {
    value: 'A03',
    label: '公路'
  },
  {
    value: 'A04',
    label: '铁路'
  },
  {
    value: 'A05',
    label: '民航'
  },
  {
    value: 'A06',
    label: '水运'
  },
  {
    value: 'A07',
    label: '水利'
  },
  {
    value: 'A08',
    label: '能源'
  },
  {
    value: 'A09',
    label: '邮电通信'
  },
  {
    value: 'A10',
    label: '桥梁'
  },
  {
    value: 'A11',
    label: '城市轨道'
  },
  {
    value: 'A12',
    label: '矿产冶金'
  },
  {
    value: 'A13',
    label: '信息网络'
  },
  {
    value: 'A14',
    label: '工业制造'
  },
  {
    value: 'A99',
    label: '其他'
  }
]
export default {
  tenderTypeList
}
