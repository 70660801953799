<!--
  @name: 对接交易底部板式（根据租户获取文案信息）
  @description: 配置了租户信息的用这个板式
  @author: 马群
  @time: 2024-07-22 09:33:16
-->
<template>
  <div class="gtm-trade-footer-com">
    <div class="main-content">
      <div v-if="linkList.length > 0" class="link-content">
        <div class="label-box">
          友情链接：
        </div>
        <div class="select-box">
          <div v-for="linkItem in linkList" :key="linkItem.name" class="select-item">
            <el-select
              style="width: 100%"
              icon="el-icon-caret-top"
              :placeholder="linkItem.name"
              :value="null"
              @change="onLinkChange"
            >
              <el-option
                v-for="item in linkItem.linkItemList"
                :key="item.name"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="line-box"></div>
      <div class="info-content">
        <div class="logo-box">
          <div class="logo-box-inner">
            <img
              v-for="(item, index) in imageList" :key="index"
              style="cursor: pointer"
              :src="require(`./image/${item.imageSrc}`)"
              @click="onInfoClick(item)"
            >
          </div>
        </div>
        <div class="vertical-line"></div>
        <div class="info-box">
          <div class="info-box-inner">
            <div v-if="platformInfo.copyright">{{ platformInfo.copyright }}</div>
            <div v-for="(item, index) in platformInfo.infoItemList" :key="index">
              <div :class="item.linkSrc ? 'link-style': ''" @click="onInfoClick(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rootState } from '../../../../@gtm-butils'

export default {
  name: 'GtmTradeFooterCom',
  components: {},
  props: {},
  data () {
    return {
      value: 1,
      linkList: [],
      imageList: [],
      platformInfo: {},
      footerConfigList: []
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    if (rootState.tenantConfig.footer.linkList) {
      this.linkList = rootState.tenantConfig.footer.linkList
    }
    if (rootState.tenantConfig.footer.platformInfo) {
      this.platformInfo = rootState.tenantConfig.footer.platformInfo
    }
    if (rootState.tenantConfig.footer.imageList) {
      this.imageList = rootState.tenantConfig.footer.imageList
    }
  },
  methods: {
    onLinkChange (item) {
      if (item.linkSrc) {
        window.open(item.linkSrc, '_blank')
      }
    },
    onInfoClick (item) {
      if (item.linkSrc) {
        window.open(item.linkSrc, '_blank')
      }
    }
  }
}
</script>
<style lang="scss">
.gtm-trade-footer-com {
  color: #99B7E9;
  width: 100%;
  //height: 306px;
  background-color: #004AC9;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .main-content {
    display: flex;
    width: 1440px;
    flex-direction: column;

    .link-content {
      height: 94px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .label-box {
        line-height: 94px;
        font-size: 14px;
        margin-right: 10px;
        min-width: 70px;
      }

      .select-box {
        display: flex;
        align-items: center;
        flex: 1;

        .select-item:last-child {
          margin-right: 0;
        }

        .select-item {
          margin-right: 15px;
          flex: 1;

          .el-select {
            .el-input {
              width: 100%;

              .el-input__inner {
                background: rgba(255, 255, 255, 0.1);
                border: none;
                border-radius: 2px;
                color: #99B7E9;
              }

              .el-input__inner::placeholder {
                color: #99B7E9 !important;
              }

              i {
                font-size: 10px;
              }

              .el-icon-caret-top:before {
                color: #99B7E9;
              }
            }
          }
        }
      }
    }

    .line-box {
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(176, 176, 176, 0.5) 48%, rgba(201, 201, 201, 0) 100%);
    }

    .info-content {
      display: flex;
      height: 150px;
      flex-direction: row;
      margin-top: 30px;

      .logo-box {
        width: 470px;
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 25px;
        position: relative;

        .logo-box-inner {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);

          img:first-child {
            margin-right: 20px;
          }
        }
      }

      .vertical-line {
        width: 2px;
        height: 150px;
        background: linear-gradient(180deg, rgba(201, 201, 201, 0) 0%, rgba(176, 176, 176, 0.5) 52%, rgba(255, 255, 255, 0) 100%);
      }

      .info-box {
        margin-left: 25px;
        width: 600px;
        font-size: 14px;
        display: flex;
        align-items: center;

        .info-box-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          div {
            margin-right: 30px;
            height: 22px;
            line-height: 22px;
          }

          .link-style {
            text-decoration: underline;
            text-underline-offset: 4px; /* 设置下划线与文本的距离 */
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
