import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 获取认证结果
   * @param params
   * @returns {*} USER_AUTH.fetchGetUserAuthResult
   */
  fetchGetUserAuthResult (params, data) {
    return axios.get(`${BASE_APP_PATH}/userAuth/getAuthResult`, data, { params }).then(res => res.data)
  },
  fetchUserAuth (params, data) {
    return axios.post(`${BASE_APP_PATH}/userAuth/auth`, data, { params }).then(res => res.data)
  },
  /**
   * 检查认证结果
   * @param params
   * @param data
   * @returns {*} USER_AUTH.fetchGetUserAuthResult
   */
  fetchUserAuthCheckAuthResult (params, data) {
    return axios.post(`${BASE_APP_PATH}/userAuth/checkAuthResult`, data, { params }).then(res => res.data)
  },
  /**
   * 生成实名认证单号
   * @param params
   * @param data
   * @returns {*} USER_AUTH.fetchUserAuthGenerateAuthOrder
   */
  fetchUserAuthGenerateAuthOrder (params, data) {
    return axios.post(`${BASE_APP_PATH}/userAuth/generAuthOrder`, data, { params }).then(res => res.data)
  }
}
