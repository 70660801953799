import { queryToParams } from 'web-utils'
import { tenantConfigList } from './tenantConfig'

let microAppName = ''

const path = window.location.pathname.split('/gtm/web/platform/')[1]
let microPath = '/'
if (path) {
  microAppName = path.split('/')[0]
  microPath = path.split(microAppName)[1]
}

let queryParams = queryToParams()
let tenantConfig = tenantConfigList.find(item => item.host === sessionStorage.getItem('TENANT_DOMAIN')) || {}

export default {
  path: microPath,
  appName: microAppName,
  query: { ...queryParams },
  userInfo: {},
  indexInfo: {},
  productList: [],
  loginStatus: false,
  tradeProjectInfo: {},
  tenantConfig: tenantConfig,
  tenantInfo: {}
}
