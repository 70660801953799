import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'
export default {
  /**
   * 保存订单(投标保函)
   * @param params
   * @returns {*} ORDER.fetchUpdateTenderOrderSave
   */
  fetchUpdateTenderOrderSave (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/saveTenderOrder`, data, { params }).then(res => res.data)
  },
  /**
   * 提交订单(投标保函)
   * @param params
   * @returns {*} ORDER.fetchUpdateTenderOrderSubmit
   */
  fetchUpdateTenderOrderSubmit (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/submitTenderOrder`, data, { params }).then(res => res.data)
  },
  /**
   * 保存订单(履约保函)
   * @param params
   * @returns {*} ORDER.fetchUpdateAfterBidOrderSave
   */
  fetchUpdateAfterBidOrderSave (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/saveAfterBidOrder`, data, { params }).then(res => res.data)
  },
  /**
   * 提交订单(履约保函)
   * @param params
   * @returns {*} ORDER.fetchUpdateAfterBidOrderSubmit
   */
  fetchUpdateAfterBidOrderSubmit (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/submitAfterBidOrder`, data, { params }).then(res => res.data)
  },
  /**
   * 查询保函订单信息
   * @param params
   * @returns {*} ORDER.fetchRequestOrderInfoByOrderNo
   */
  fetchRequestOrderInfoByOrderNo (orderNo, params) {
    return axios.get(`${GUARANTEE_APP_PATH}/order/find/${orderNo}`, { params }).then(res => res.data)
  },
  /**
   * 查询保函申请结果
   * @param params
   * @returns {*} ORDER.fetchGetGuaranteeResult
   */
  fetchGetGuaranteeResult (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/guaranteeResult`, data, { params }).then(res => res.data)
  },
  /**
   * 取消订单
   * @param params
   * @returns {*} ORDER.fetchCancelOrder
   */
  fetchCancelOrder (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/cancel`, data, { params }).then(res => res.data)
  },
  /**
   * 订单导出pdf
   * @param params
   * @param data
   * @returns {*} ORDER.fetchOrderExportPdf
   */
  fetchOrderExportPdf (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/exportPdf`, data, { params }).then(res => res.data)
  },
  /**
   * 订单列表
   * @param params
   * @param data
   * @returns {*} ORDER.fetchOrderList
   */
  fetchOrderList (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/list`, data, { params }).then(res => res.data)
  },
  /**
   * 删除订单
   * @param params
   * @param data
   * @returns {*} ORDER.fetchDeleteOrder
   */
  fetchDeleteOrder (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/delete`, data, { params }).then(res => res.data)
  },
  /**
   * 重新申请
   * @param params
   * @param data
   * @returns {*} ORDER.fetchOrderReapply
   */
  fetchOrderReapply (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/reSave`, data, { params }).then(res => res.data)
  },
  /**
   * 保函预览导出
   * @param params
   * @param data
   * @returns {*} ORDER.fetchGuaranteeExport
   */
  fetchGuaranteeExport (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/exportGuarantee`, params, data).then(res => res.data)
  },
  /**
   * 查询订单扩展数据（查询是否点击了退保同意）
   * @param params
   * @returns {*} ORDER.fetchRequestSurrenderAgreeInfoByOrderNo
   */
  fetchRequestSurrenderAgreeInfoByOrderNo (orderNo, params) {
    return axios.get(`${GUARANTEE_APP_PATH}/order/extension/${orderNo}`, { params }).then(res => res.data)
  },
  /**
   * 更新订单扩展数据（更新退保同意）
   * @param params
   * @param data
   * @returns {*} ORDER.fetchUpdateSurrenderAgreeInfo
   */
  fetchUpdateSurrenderAgreeInfo (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/extension`, data, { params }).then(res => res.data)
  },
  /**
   * 根据项目信息查询是否有各种状态下的订单
   * @param params
   * @param data
   * @returns {*} ORDER.fetchStatefulOrderList
   */
  fetchStatefulOrderList (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/completeList`, data, { params }).then(res => res.data)
  },
  /**
   * 受益保函列表
   * @param params
   * @param data
   * @returns {*} ORDER.fetchBeneficiaryList
   */
  fetchBeneficiaryList (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/order/beneficiaryList`, data, { params }).then(res => res.data)
  }
}
