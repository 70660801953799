<!--
  @name: 左侧菜单栏
  @description:
  @author: 马群
  @time: 2023-12-01 10:57:54
-->
<template>
  <div class="gtm-base__page-aside-menu" :class="isCollapse ? 'aside-menu--min' : ''">
    <div class="left-side-top">
      <el-menu
        :default-openeds="userMenus"
        :default-active="activePath"
        class="el-menu-vertical"
        :collapse="isCollapse"
        @select="handleMenuSelect"
      >
        <template>
          <div v-for="(items, index) in secondList" :key="index">
            <el-menu-item v-if="items.resUrl && items.children.length === 0" :index="items.resUrl">
              <i :class="items.iconCssClass"></i>
              <span slot="title">{{ items.resName }}</span>
            </el-menu-item>
            <el-submenu v-else :index="items.resId">
              <template slot="title">
                <i class="el-icon-setting bg-icon"></i>
                <span>{{ items.resName }}</span>
              </template>
              <el-menu-item
                :index="item.resUrl" v-for="(item, order) in items.children" :key="order"
              >{{ item.resName }}
              </el-menu-item>
            </el-submenu>
          </div>
        </template>
      </el-menu>
    </div>
    <div class="left_side_bottom">
      <div class="nav_bg"></div>
      <div class="control_btn">
        <div @click="() => (this.isCollapse = !this.isCollapse)" class="btn_fold">
          <i class="gtm-iconfont" :class="isCollapse ? 'icon-zhankai1' : 'icon-a-arrow-downbeifen44'"></i>
          <div class="tips">{{ isCollapse ? '' : '收起' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { rootState } from '@gtm-butils'

export default {
  name: 'AsideMenu',
  components: {},
  mixins: [],
  props: {},
  data () {
    return {
      isCollapse: false,
      currentRootState: {},
      secondList: [],
      userMenus: []
    }
  },
  computed: {
    ...mapState(['rootFullPath', 'rootMicroAppData', 'rootActivePath']),
    activePath () {
      return this.rootActivePath || this.rootFullPath
    }
  },
  watch: {
    currentRootState: {
      handler: function (value) {
        if (this.$rootState?.indexInfo?.menus) {
          let activeNode = this.findNodeInTree(this.$rootState?.indexInfo?.menus, this.activePath, 'resUrl')
          let activeIndex = activeNode?.parentId === 0 ? activeNode?.resUrl : activeNode?.parentId
          this.secondList = this.$rootState?.indexInfo?.menus?.children || []
          this.userMenus = [activeIndex]
          console.log(this.activePath, 'watch')
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.currentRootState = rootState
    console.log(this.activePath, '【base-platform】【asideMenu】组件mounted')
  },
  methods: {
    // 查找树中的节点
    findNodeInTree (tree, targetValue, searchProperty = 'value') {
      // 基本情况：如果当前节点就是要找的目标值，返回当前节点
      if (tree[searchProperty] === targetValue) {
        return tree
      }

      // 如果当前节点有子节点（数组）
      if (Array.isArray(tree.children)) {
        for (let child of tree.children) {
          // 对每个子节点进行递归查找
          const foundNode = this.findNodeInTree(child, targetValue, searchProperty)
          // 如果找到了目标节点，则返回该节点
          if (foundNode !== null) {
            return foundNode
          }
        }
      }

      // 如果没有找到，则返回null或undefined（表示未找到）
      return null
    },
    // 菜单点击
    handleMenuSelect (path) {
      this.$router.push({
        path: `/platform/${path}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$activeColor: #2371f7;
.el-menu--popup--light .el-menu--popup-right-start .el-menu-item.is-active {
  background-color: rgba(0, 130, 230, 0.1) !important;
  color: $activeColor !important;
}

.gtm-base__page-aside-menu {
  position: relative;
  transition: all 0.2s;
  left: 0px;
  bottom: 0;
  z-index: 1;
  height: 100%;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background: #f2f3f5;
  padding: 16px 16px 0;

  .el-menu-vertical {
    background: #f2f3f5;
    padding: 0px;

    &:not(.el-menu--collapse) {
      width: 220px;
      // min-height: 400px;
      .el-submenu__title {
        padding-left: 12px !important;
      }
    }

    &.el-menu--collapse {
      width: 60px;
    }

    .el-submenu {
      // padding-left:2px;
      > .el-submenu__title:hover {
        background-color: #d7dce3 !important;
      }

      &.is-active {
        > .el-submenu__title {
          font-weight: bold;
          color: #333333 !important;
          // background-color: #D7DCE3 !important;
          &::before {
            content: none;
          }
        }

        .bg-icon {
          background: url(~assets/images/navIcon.png);
          background-size: 16px 16px;
          color: transparent;
          font-size: 16px;
        }
      }

      .el-submenu__icon-arrow,
      .el-icon-arrow-down {
        margin-right: 10px;
      }

      .bg-icon {
        font-size: 16px;
        background: url(~assets/images/leftMenu/common.png);
        background-size: 16px 16px;
        color: transparent;
      }
    }

    .el-submenu.is-active &.el-submenu [class*='icon-'],
    .el-submenu [class^='el-icon-'] {
      margin-right: 15px !important;
    }

    .el-submenu [class^='icon-'] {
      margin-right: 15px !important;
    }

    .el-menu-item {
      height: 50px;
      padding-left: 36px;

      &:hover {
        width: 208px;
        background: #e4e6e7;
        border-radius: 8px;
      }

      &.is-active {
        &:hover {
          width: 208px;
          background: #dfe6f8;
          border-radius: 8px;
        }

        width: 208px;
        background: #dfe6f8;
        border-radius: 8px;
        color: $activeColor !important;
        font-weight: bold;
      }
    }
  }

  .left-side-top {
    height: calc(100% - 64px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .left_side_bottom {
    position: absolute;
    //max-width: 240px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -100;
    overflow: hidden;

    .nav_bg {
      height: 186px;
      width: 100%;
      background: url(~assets/images/leftMenuBg.png) no-repeat;
      background-size: 240px 186px;
      border-bottom: 1px solid #dddee0;
    }

    .control_btn {
      display: flex;
      align-items: center;
      height: 54px;
      // width: 100%;
      width: 240px;
      background: rgba(242, 243, 245, 0.8);

      &.control_btnIsCollapse {
        justify-content: center;

        .btn_fold {
          margin-left: 0;

          i {
            margin-right: 0px;
          }
        }
      }

      .btn_fold {
        display: flex;
        margin-left: 18px;
        align-items: center;
        cursor: pointer;
        // width: 15px;
        i {
          font-size: 14px;
          margin-right: 10px;
        }

        .tips {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #666666;
        }

        // height: 12px;
      }
    }
  }
}

.aside-menu--min {
  padding: 10px 0 0;
}
</style>
