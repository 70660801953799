import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 获取所有申请信息
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApprove
   */
  fetchGetInstitutionApprove (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionApprove/applylist`, data, { params }).then(res => res.data)
  },
  /**
   * 通过applyId获取申请信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApproveByApplyId
   */
  fetchGetInstitutionApproveByApplyId (applyId, params) {
    return axios
      .get(`${DEVELOPMENT_PATH}/institutionApprove/findApplyInfo/${applyId}`, { params })
      .then(res => res.data)
  },
  /**
   * 提交审核
   * @param params
   * @param data
   * @returns {*} $developmentApi.INSTITUTION.fetchUpdateInstitutionApprove
   */
  fetchUpdateInstitutionApprove (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/institutionApprove/submit`, data, { params }).then(res => res.data)
  },
  /**
   * 通过地区编码获取交易平台信息
   * @param areaCode
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApprovePlatforms
   */
  fetchGetInstitutionApprovePlatforms (params) {
    return axios.get(`${DEVELOPMENT_PATH}/institutionApprove/queryPlatforms`, { params }).then(res => res.data)
  },
  /**
   * 通过平台编码获取产品信息
   * @param platformCode
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApproveProducts
   */
  fetchGetInstitutionApproveProducts (platformCode, params) {
    return axios
      .get(`${DEVELOPMENT_PATH}/institutionApprove/queryProducts/${platformCode}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过产品编码获取产品版本信息
   * @param productCode
   * @param params
   * @returns {*} $developmentApi.INSTITUTION.fetchGetInstitutionApproveVersions
   */
  fetchGetInstitutionApproveVersions (productCode, params) {
    return axios
      .get(`${DEVELOPMENT_PATH}/institutionApprove/queryVersions/${productCode}`, { params })
      .then(res => res.data)
  }
}
