<template>
  <div class="gtm-micro-app__page">
    <div class="gtm-micro-app__guarantee" id="guarantee-web"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'guarantee',
  props: {},
  data () {
    return {
      microApp: null,
      microAppRender: false
    }
  },
  computed: {
    ...mapState(['rootMicroAppData'])
  },
  created () {
    // prefetchApps([
    //   {
    //     name: 'portal',
    //     entry: `${window.location.origin}/portal`
    //   }
    // ])
    // console.log('预加载门户应用的静态资源')
  },
  mounted () {
    if (this.$store.state.activeMicroApp.instance && this.$store.state.activeMicroApp.appName === 'guarantee-web') {
      this.$store.state.activeMicroApp.instance.unmount()
    }
    this.microApp = this.$loadMicroApp({
      name: 'guarantee-web',
      entry: `//${window.location.host}/guarantee-web`,
      container: '#guarantee-web',
      activeRule: '/gtm/web/platform/guarantee-web',
      props: { data: this.rootMicroAppData },
      sandbox: {
        // 可选，调整沙箱配置
        strictStyleIsolation: true, // 强制严格样式隔离，默认为true
        experimentalStyleIsolation: true
        // ...其他沙箱配置
      }
    })
    this.$store.state.activeMicroApp = {
      instance: this.microApp,
      appName: 'guarantee-web'
    }
    console.log('【base-platform】【guarantee】子应用执行mounted方法')
  },
  beforeRouteLeave (to, from, next) {
    // console.log(this.microApp, '【guarantee】子应用卸载成功！')
    if (this.microApp !== null) {
      this.microApp.unmount()
      console.log(this.microApp, '【guarantee】子应用卸载成功！')
    }
    next()
  },
  methods: {}
}
</script>

<style lang="scss">
.gtm-micro-app__page {
  width: 100%;
  height: 100%;

  .gtm-micro-app__guarantee {
    height: 100%;
  }

  #__qiankun_microapp_wrapper_for_guarantee_web__ {
    height: 100%;
  }
}
</style>
