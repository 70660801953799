<template>
  <el-container class="gtm-layout" :class="{ 'is-vertical': isVertical }">
    <slot></slot>
  </el-container>
</template>

<script>
export default {
  name: 'GtmLayout',
  props: {
    direction: String
  },
  computed: {
    isVertical () {
      if (this.direction === 'vertical') {
        return true
      } else if (this.direction === 'horizontal') {
        return false
      }
      return this.$slots && this.$slots.default
        ? this.$slots.default.some(vnode => {
          const tag = vnode.componentOptions && vnode.componentOptions.tag
          return tag === 'gtm-header'
        })
        : false
    }
  }
}
</script>
