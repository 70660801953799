import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 获取平台基础用户数据
   * @param params
   * @returns {*} COMMON.fetchIndexData
   */
  fetchIndexData (params) {
    return axios.get(`${BASE_APP_PATH}/common/getIndexData`, { params }).then(res => res.data)
  }
}
