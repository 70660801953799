<template>
  <div class="gtm-micro-app__page">
    <div class="gtm-micro-app__portal" id="portal"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'portal',
  props: {},
  data () {
    return {
      microApp: null,
      microAppRender: false
    }
  },
  computed: {
    ...mapState(['rootMicroAppData'])
  },
  created () {
    // prefetchApps([
    //   {
    //     name: 'guarantee-web',
    //     entry: `${window.location.origin}/guarantee-web`
    //   }
    // ])
    // console.log('预加载保函申请的静态资源')
  },
  mounted () {
    if (this.$store.state.activeMicroApp.instance && this.$store.state.activeMicroApp.appName === 'portal') {
      this.$store.state.activeMicroApp.instance.unmount()
    }
    this.microApp = this.$loadMicroApp({
      name: 'portal',
      entry: `//${window.location.host}/portal`,
      container: '#portal',
      activeRule: '/gtm/web/platform/portal',
      props: { data: this.rootMicroAppData },
      sandbox: {
        // 可选，调整沙箱配置
        strictStyleIsolation: true, // 强制严格样式隔离，默认为true
        experimentalStyleIsolation: true
        // ...其他沙箱配置
      }
    })
    this.$store.state.activeMicroApp = {
      instance: this.microApp,
      appName: 'portal'
    }
    console.log('window.portal', window.portal)
    console.log('【base-platform】【portal】子应用执行mounted方法')
  },
  beforeRouteLeave (to, from, next) {
    if (this.microApp !== null) {
      this.microApp.unmount()
      console.log(this.microApp, '【portal】子应用卸载成功！')
    }
    next()
  },
  methods: {}
}
</script>

<style lang="scss">
.gtm-micro-app__page {
  width: 100%;
  height: 100%;

  .gtm-micro-app__portal {
    height: 100%;
  }
}
</style>
