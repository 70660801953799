import { Cascader } from 'element-gui'

import GtmAreaCascader from './GtmAreaCascader'

GtmAreaCascader.install = function (Vue) {
  Vue.use(Cascader)
  Vue.component(GtmAreaCascader.name, GtmAreaCascader)
}

export default {
  GtmAreaCascader
}
