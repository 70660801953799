import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'

export default {
  /**
   * 申请发票
   * @param params
   * @returns {*} INVOICE.fetchUpdateInvoice
   */
  fetchUpdateInvoice (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/invoice/apply`, data, { params })
      .then(res => res.data)
  },
  /**
   * 查询发票结果
   * @param params
   * @returns {*} INVOICE.fetchGetInvoiceInfoByOrderNo
   */
  fetchGetInvoiceInfoByOrderNo (orderNo, params) {
    return axios.get(`${GUARANTEE_APP_PATH}/invoice/find/${orderNo}`, { params })
      .then(res => res.data)
  }
}
