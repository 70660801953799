import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 租户管理员下发的机构信息列表
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchTenantEffectiveList
   */
  fetchTenantEffectiveList (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/list`, data, { params }).then(res => res.data)
  },
  /**
   * 租户管理员下发的机构信息禁用、启用
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchTenantEffectiveEnable
   */
  fetchTenantEffectiveEnable (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/enable`, data, { params }).then(res => res.data)
  },
  /**
   * 租户管理员下发的机构信息上线、下线
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchTenantEffectiveOnline
   */
  fetchTenantEffectiveOnline (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/online`, data, { params }).then(res => res.data)
  },
  /**
   * 租户管理员下发的机构-通过机构id查询机构信息
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchTenantEffectiveFindById
   */
  fetchTenantEffectiveFindById (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/findById`, data, { params }).then(res => res.data)
  },
  /**
   * 租户管理员下发的机构-编辑机构信息
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchUpdateTenantEffective
   */
  fetchUpdateTenantEffective (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/update`, data, { params }).then(res => res.data)
  },
  /**
   * 租户管理员下发的机构-删除机构信息
   * @param params
   * @param data
   * @returns {*} $sysappApi.TENANT.fetchDeleteTenantEffective
   */
  fetchDeleteTenantEffective (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/effective/deleteById`, data, { params }).then(res => res.data)
  }
}
