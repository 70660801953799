import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 获取平台基础用户数据
   * @param params
   * @returns {*} $guaranteeApi.COMMON_LOGIN.fetchToLoginOut
   */
  fetchToLoginOut (params) {
    return axios.get(`${BASE_APP_PATH}/commonlogin/loginOut`, { params }).then(res => res.data)
  },
  /**
   * 获取publicKey
   * @param params
   * @returns {*} $guaranteeApi.COMMON_LOGIN.fetchGetPublicKey
   */
  fetchGetPublicKey (params) {
    return axios.get(`${BASE_APP_PATH}/commonlogin/getPublicKey`, { params }).then(res => res.data)
  },
  /**
   * 登录功能
   * @param params
   * @param data
   * @returns {*} $guaranteeApi.COMMON_LOGIN.fetchToLogin
   */
  fetchToLogin (params, data) {
    return axios.post(`${BASE_APP_PATH}/commonlogin/toLogin`, data, { params }).then(res => res.data)
  }
}
