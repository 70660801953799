import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 获取认证结果
   * @param params
   * @returns {*} ENTERPRISE_AUTH.fetchGetUserAuthResult
   */
  fetchGetEnterpriseAuthResult (params) {
    return axios.get(`${BASE_APP_PATH}/enterprise/authResult`, { params, withCredentials: true }).then(res => res.data)
  },
  /**
   *
   * @param params
   * @param data
   * @returns {*} ENTERPRISE_AUTH.fetchEnterpriseAuth
   */
  fetchEnterpriseAuth (params, data) {
    return axios.post(`${BASE_APP_PATH}/enterprise/auth`, data, { params }).then(res => res.data)
  }
}
