// 定义埋点信息
const trackingPoolMap = {
  '1501001': {
    fnname: '进入_自营平台3.0-首页_PV（UV）',
    fngroup: '自营平台3.0-首页'
  },
  '1501004': {
    fnname: '点击【自营平台3.0-首页-保函服务-投标-立即申请】按钮',
    fngroup: '自营平台3.0-首页'
  },
  '1501005': {
    fnname: '点击【自营平台3.1-首页-保函服务-履约-立即申请】按钮',
    fngroup: '自营平台3.0-首页'
  },
  '1502001': {
    fnname: '进入_自营平台3.0-金融机构选择_PV（UV）',
    fngroup: '自营平台3.0-保函申请（金融机构选择）'
  },
  '1502002': {
    fnname: '点击【自营平台3.0-金融机构选择-tabs-投标】标签',
    fngroup: '自营平台3.0-保函申请（金融机构选择）'
  },
  '1502003': {
    fnname: '点击【自营平台3.0-金融机构选择-tabs-履约】标签',
    fngroup: '自营平台3.0-保函申请（金融机构选择）'
  },
  '1502005': {
    fnname: '点击【自营平台3.0-金融机构选择-投标-立即申请）】按钮',
    fngroup: '自营平台3.0-保函申请（金融机构选择）'
  },
  '1502006': {
    fnname: '点击【自营平台3.0-金融机构选择-履约-立即申请）】按钮',
    fngroup: '自营平台3.0-保函申请（金融机构选择）'
  },
  '1503001': {
    fnname: '进入_自营平台3.0-投标保函申请_保函申请PV（UV）',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1503002': {
    fnname: '点击【自营平台3.0-投标保函申请-提交】按钮',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1503008': {
    fnname: '点击【自营平台3.0-投标保函申请-提交-确定】按钮',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1504001': {
    fnname: '进入_自营平台3.0-投标保函申请_费用支付PV（UV）',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1504002': {
    fnname: '点击【自营平台3.0-投标保函申请-立即支付】按钮',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1505001': {
    fnname: '进入_自营平台3.0-投标保函申请_保函生成PV（UV）',
    fngroup: '自营平台3.0-投标保函申请'
  },
  '1506001': {
    fnname: '进入_自营平台3.0-履约保函申请_保函申请PV（UV）',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1506002': {
    fnname: '点击【自营平台3.0-履约保函申请-提交】按钮',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1506005': {
    fnname: '点击【自营平台3.0-履约保函申请-提交-确定】按钮',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1507001': {
    fnname: '进入_自营平台3.0-履约保函申请_费用支付PV（UV）',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1507002': {
    fnname: '点击【自营平台3.0-履约保函申请-立即支付】按钮',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1508001': {
    fnname: '进入_自营平台3.0-履约保函申请_保函生成PV（UV）',
    fngroup: '自营平台3.0-履约保函申请'
  },
  '1509001': {
    fnname: '进入_自营平台3.0-关于我们PV（UV）',
    fngroup: '自营平台3.0-关于我们'
  },
  '1510001': {
    fnname: '进入_自营平台3.0-订单管理PV（UV）',
    fngroup: '自营平台3.0-订单管理'
  },
  '1513003': {
    fnname: '点击【自营平台3.0-首页- 顶部导航-保函申请】按钮',
    fngroup: '自营平台3.0-通用'
  },
  '1513004': {
    fnname: '点击【自营平台3.0-首页- 顶部导航-我的订单】按钮',
    fngroup: '自营平台3.0-通用'
  },
  '1514001': {
    fnname: '进入_自营平台3.0-登录PV（UV）',
    fngroup: '自营平台3.0-登录'
  },
  '1514002': {
    fnname: '点击【自营平台3.0-登录页-登录】按钮',
    fngroup: '自营平台3.0-登录'
  },
  '1515001': {
    fnname: '进入_自营平台3.0-注册PV（UV）',
    fngroup: '自营平台3.0-注册'
  },
  '1515002': {
    fnname: '点击【自营平台3.0-注册页-注册】按钮',
    fngroup: '自营平台3.0-注册'
  }
}

export default trackingPoolMap

export { trackingPoolMap }
