import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  rootRouterInfo: {}, // 全局路由的信息
  rootFullPath: '', // 子应用路由信息
  rootActivePath: '',
  rootMicroAppData: null, // 全局上下文信息
  activeMicroApp: {
    instance: null,
    appName: ''
  },
  showAside: false,
  showHeader: false,
  showHeaderMenu: false,
  showHeaderHome: false,
  showHeaderLogin: false,
  showUserInfo: true,
  baseFullPath: '',
  defaultMenuInfo: {
    defaultMenu: [],
    defaultUrl: '',
    defaultPath: ''
  },
  staticFixedPath: process.env.NODE_ENV === 'development' ? `/static` : `${document.location.origin}/gtm/web/static`,
  isLoginExpire: false // 是否登录状态过期
}

const mutations = {}

const actions = {}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
