// 兼容ie11
import 'es6-promise/auto' // 自动 polyfill Promise
import 'whatwg-fetch'
import 'custom-event-polyfill'
import 'core-js/stable'
import 'core-js/web/url'
import 'core-js/stable/promise'
import 'core-js/stable/symbol'
import 'core-js/stable/string/starts-with'
import 'regenerator-runtime/runtime'
// 框架相关
import Vue from 'vue'
import App from './App.vue'
import store from './vuex'
import router from './router/router'
import axios from 'axios'

// UI库
import ElementUI from 'element-gui'
import '@gtm-components/element-gui-variables.scss'

import '@/assets/css/iconfont/iconfont.css'
import '@/assets/css/base.scss'

// 内部依赖-GtmUtils工具库
import GtmUtils, { queryToParams, getCookie } from 'web-utils'
import GtmButils, { rootState } from '@gtm-butils'
import '@gtm-components/assets/iconfont/iconfont.css'
import GtmApi, { baseAppApi } from '@gtm-api'
import GtmComponents from '@gtm-components'
import initAxios from '@gtm-api/axios-config'
import { tenantConfigList } from '@gtm-butils/root-state/tenantConfig'
import { loadMicroApp } from 'qiankun'

Vue.use(ElementUI)
Vue.use(GtmUtils)
Vue.use(GtmButils)
Vue.use(GtmApi)
Vue.use(GtmComponents)

Vue.config.productionTip = false
initAxios.initAxiosInterceptor()
Vue.prototype.$loadMicroApp = loadMicroApp

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled Rejection:', event.reason)
  // 根据需要处理错误
})

// 这里的逻辑是先请求租户的配置，再渲染主应用；不然全局路由守卫执行太快了
const requestUserTenantInfo = () => {
  let params = {}
  let query = queryToParams()
  const token = getCookie('SESSION_TOKEN')
  if (query?.token || token) {
    params = {
      token: query?.token || token
    }
  }
  baseAppApi.USER.fetchGetUserTenant(params)
    .then(res => {
      rootState.tenantInfo = res.data || {}
      sessionStorage.setItem('TENANT_DOMAIN', res?.data?.domain || '')
      sessionStorage.setItem('TENANT_ORIGIN_DOMAIN', res?.data?.originDomain || '')
      sessionStorage.setItem('TENANT_INFO', JSON.stringify(res?.data))
      console.log('【base-platform】【main.js】获取租户原始域名：', res?.data?.domain || '')
      rootState.tenantConfig = tenantConfigList.find(item => item.host === sessionStorage.getItem('TENANT_DOMAIN')) || {}
      console.log('【base-platform】【main.js】获取租户原始域名下的租户配置', rootState.tenantConfig)
      let customerIp = localStorage.getItem('CUSTOMER_IP')
      if (!customerIp) {
        axios.get(`/source/addr`)
          .then(res => {
            if (res && res.data) {
              localStorage.setItem('CUSTOMER_IP', res.data)
            } else {
              localStorage.setItem('CUSTOMER_IP', Math.floor(10000000 + Math.random() * 90000000))
            }
          })
      }
      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    })
}
requestUserTenantInfo()
