<template>
  <div class="verifyContent">
    <el-dialog custom-class="verifyDialog" :visible.sync="dialogVisible" :show-close="false" @open="onDialogOpen">
      <div class="headerImg">
        <img src="../../assets/image/gtmGuaranteeVerify/verifyHeader.png"/>
        <div class="title">
          <div class="mainTitle">
            <div class="leftIcon"></div>
            <span class="mainText">电子保函验真</span>
            <div class="rightIcon"></div>
          </div>
          <div class="subTitle">
            <span class="leftLine"></span>
            <span
              class="subText"
            >在线
              <div class="searchIcon"></div>
              查验真伪，使用安全放心</span
            >
            <span class="rightLine"></span>
          </div>
        </div>
        <div class="closeDialog" @click="dialogVisible = false"></div>
      </div>
      <div style="padding-bottom: 40px">
        <div class="searchContent">
          <div class="searchForm">
            <el-form ref="ruleForm" :model="searchForm" :grid="isGrid" :rules="rules">
              <el-form-item label="保函编号" :span="12" prop="guaranteeNo" label-width="110px">
                <el-input v-model="searchForm.guaranteeNo" placeholder="请输入保函编号"></el-input>
              </el-form-item>
              <el-form-item label="验证码" :span="12" prop="kapcha" label-width="70px">
                <el-input class="kapcha-input" v-model="searchForm.kapcha" placeholder="请输入验证码">
                  <template
                    slot="append"
                  ><img
                    :src="verifyCodeUrl" @click="freshKaptchaImage" class="verifyCodeImg"
                  /></template>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="operate">
              <el-button size="medium" type="primary" @click="onVerify">验真</el-button>
              <el-button size="medium" @click="reset" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="mainContent">
          <div class="validResult" v-if="verifyInfo.resultCode === true && verifyInfo.orderStatus !== 'SURRENDERED'">
            <div class="validResultText">验真成功 ！</div>
            <el-descriptions class="descriptions_container" labelClassName="text-Label" :column="2">
              <el-descriptions-item>
                <template slot="label"> 保函编号</template>
                <span v-if="verifyInfo.guaranteeNo">{{ verifyInfo.guaranteeNo || '--' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 保函类型</template>
                <span v-if="verifyInfo.productTypeName">{{ verifyInfo.productTypeName || '--' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 申请企业</template>
                <span v-if="verifyInfo.enterpriseName">{{ verifyInfo.enterpriseName || '--' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 开函金融机构</template>
                <span v-if="verifyInfo.merchantName">{{ verifyInfo.merchantName || '--' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 有效期</template>
                <span>{{ verifyInfo.guaranteeBeginTime || '--' }}~{{ verifyInfo.guaranteeEndTime || '--' }}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label"> 开具时间</template>
                <span v-if="verifyInfo.guaranteeTime">{{ verifyInfo.guaranteeTime || '&#45;&#45;' }}</span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="invalidResult" v-else-if="verifyInfo.resultCode === false">
            <div class="invalidResultText">未查验到 ！</div>
            <div class="validResultPeriod">
              保函编号：{{ verifyInfo.guaranteeNo || '--' }}，未查验到此保函，请重新查询 ！
            </div>
          </div>
          <div class="emptyResult" v-else>
            <img src="../../assets/image/gtmGuaranteeVerify/empty.png" style="width: 125px; height: 109px"/>
            <div class="emptyText">暂无数据，请在上方查询</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { guaranteeApi } from '@gtm-api'
import { bigNumberFormat } from 'web-utils'

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isGrid: true,
      resultStatus: '',
      searchForm: {
        guaranteeNo: '',
        kapcha: '',
        productCode: 'TENDER'
      },
      // 验证码链接
      verifyCodeUrl: this.$guaranteeApi.USER.fetchUserGenVerifyImage(),
      verifyInfo: {
        guaranteeNo: '',
        resultCode: '',
        resultMsg: '',
        merchantName: null,
        enterpriseName: null,
        tenderer: null,
        projectCode: null,
        projectName: null,
        tenderBond: null,
        guaranteeTime: null,
        guaranteeBeginTime: null,
        guaranteeEndTime: null,
        orderStatus: null
      },
      rules: {
        guaranteeNo: [{ required: true, message: '请输入保函编号', trigger: 'blur' }],
        kapcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },

  computed: {
    dialogVisible: {
      get () {
        return this.visible
      },
      set () {
        this.$emit('update:visible', false)
      }
    }
  },

  mounted () {
  },

  methods: {
    onDialogOpen () {
      // 初始化状态
      this.searchForm = {
        guaranteeNo: '',
        kapcha: '',
        productCode: 'TENDER'
      }
      this.verifyInfo = {}
    },
    freshKaptchaImage () {
      // 每次刷新重新获取验证码
      this.verifyCodeUrl = this.verifyCodeUrl + '?t=' + new Date().getTime()
    },
    onVerify () {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.requestUpdateVerifyInfo()
          this.freshKaptchaImage() // 点完验真后更新一下验证码
        }
      })
    },
    requestUpdateVerifyInfo () {
      let body = {
        guaranteeNo: this.searchForm.guaranteeNo,
        kaptcha: this.searchForm.kapcha,
        productCode: this.searchForm.productCode
      }
      guaranteeApi.GUARANTEE_API.fetchGuaranteeVerify({}, body)
        .then(data => {
          if (data.code === 0) {
            this.verifyInfo = data.data
          }
        })
        .catch(() => {
        })
        .finally(() => {
        })
    },
    reset () {
      this.searchForm.guaranteeNo = ''
      this.searchForm.kapcha = ''
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    bigNumberFormat (data) {
      return bigNumberFormat(data)
    }
  }
}
</script>
<style lang="scss">
.verifyContent {
  .verifyDialog {
    width: 1280px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(6, 34, 76, 0.2);
    border-radius: 8px;
    font-family: MicrosoftYaHei;

    .el-dialog__body {
      padding: 0;
      border-radius: 8px;
    }

    .headerImg {
      width: 100%;
      height: 180px;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }

      .title {
        width: 592px;
        height: 92px;
        z-index: 1;

        .mainTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 40px;
          line-height: 52px;
          font-weight: bold;
          color: #ffffff;

          .leftIcon {
            background: url(../../assets/image/gtmGuaranteeVerify/titleLeftIcon.png);
            background-size: 100% 100%;
            width: 22px;
            height: 22px;
          }

          .mainText {
            margin: 0px 30px;
          }

          .rightIcon {
            background: url(../../assets/image/gtmGuaranteeVerify/titleRightIcon.png);
            background-size: 100% 100%;
            width: 22px;
            height: 22px;
          }
        }

        .subTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          margin-top: 16px;
          color: #ffffff;
          line-height: 24px;

          .leftLine {
            width: 152px;
            border-top: 1px solid;
            border-image: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
          }

          .subText {
            margin: 0px 10px;
            display: flex;
            align-items: center;

            .searchIcon {
              background: url(../../assets/image/gtmGuaranteeVerify/search.png);
              background-size: 100% 100%;
              margin: 0px 6px;
              width: 16px;
              height: 16px;
            }
          }

          .rightLine {
            width: 153px;
            border-top: 1px solid;
            border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 1 1;
          }
        }
      }

      .closeDialog {
        position: absolute;
        top: 20px;
        right: 20px;
        background: url(../../assets/image/gtmGuaranteeVerify/close.png);
        background-size: 100% 100%;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    .searchContent {
      margin-top: 20px;

      .searchForm {
        display: flex;

        .verifyCodeImg {
          height: 32px;
          margin-bottom: -2px;
        }

        .kapcha-input {
          width: 300px;
        }

        .el-input-group__append,
        .el-input-group__prepend {
          padding: 0px;
        }

        .operate {
          margin-left: 50px;
          margin-top: 2px;
        }
      }
    }

    .mainContent {
      margin: 0px 40px;
      min-height: 240px;
      background: linear-gradient(180deg, #e5edfe 0%, #f9fafc 100%);
      border-radius: 4px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(200, 210, 230, 1), rgba(221, 230, 243, 1)) 1 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .emptyResult,
      .validResult,
      .invalidResult {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .emptyText {
          font-size: 14px;
          color: #666666;
          line-height: 17px;
        }

        .validResultText,
        .invalidResultText {
          font-size: 20px;
          color: #333333;
          line-height: 27px;
          position: relative;
        }

        .validResultText::before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(../../assets/image/gtmGuaranteeVerify/success.png);
          background-size: 100%;
          left: -25px;
          top: 3px;
        }

        .invalidResultText::before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(../../assets/image/gtmGuaranteeVerify/failed.png);
          background-size: 100%;
          left: -25px;
          top: 3px;
        }

        .validResultPeriod {
          margin-top: 10px;
          font-size: 12px;
          color: #666666;
          line-height: 19px;
        }

        .descriptions_container {
          margin-top: 30px;
          width: 100%;

          .el-descriptions__body {
            background-color: transparent;
          }

          .text-Label {
            width: 220px;
            font-size: 14px;
            font-weight: normal;
            display: flex;
            justify-content: flex-end;
            color: #333333;
          }

          .el-descriptions-item__content {
            font-weight: bold;
            padding-left: 5px !important;
          }
        }
      }
    }
  }
}
</style>
