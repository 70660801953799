/**
 * 将指定或当前Url的Query解析为参数对象
 * @param {string} query 指定的Query，未传入则取当前Url的Query（hash或search）
 * @returns {{}|null} 存在Query参数将返回参数对象，否则返回null
 */
export const queryToParams = (query = '') => {
  if (!query) {
    query = window.location.hash || window.location.search
  }
  if (!query) return {}
  // 这里会影响特殊字符参数的传递，先注释掉，以后有问题再解决
  // if (query.indexOf('%3D') > -1) {
  //   query = decodeURIComponent(query)
  // }
  const reg = /([^=&?#/\s]+)=([^=&\s]+)/g
  const obj = {}
  while (reg.exec(query)) {
    obj[decodeURIComponent(RegExp.$1)] = decodeURIComponent(RegExp.$2)
  }
  if (!Object.keys(obj).length) return {}
  return obj
}

/**
 * 将参数对象转换为Query字符串
 * @param {object} params 参数对象
 * @returns {string} Query字符串
 */
export const paramsToQuery = params => {
  if (!params) return ''
  const pairs = []
  for (const key in params) {
    const val = `${key}=${params[key]}`
    pairs.push(val)
  }
  return pairs.join('&')
}

/**
 * 获取Cookie值
 * @param {string} name Cookie名
 * @returns Cookie值
 */
export const getCookie = name => {
  if (!name) return ''
  name = name + '='
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const c = cookies[i].trim()
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }
  return ''
}

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toGMTString()
  document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=/'
}
