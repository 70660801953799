import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 产品列表
   * @param params
   * @returns {*} $sysappApi.PRODUCT.fetchGetProductList
   */
  fetchGetProductList (params) {
    return axios.get(`${SYS_APP_PATH}/product/list`, { params }).then(res => res.data)
  },
  /**
   * 创建产品
   * @param params
   * @returns {*} $sysappApi.PRODUCT.fetchAddProduct
   */
  fetchAddProduct (params, data) {
    return axios.post(`${SYS_APP_PATH}/product/create`, data, { params }).then(res => res.data)
  },
  /**
   * 删除产品
   * @param params
   * @returns {*} $sysappApi.PRODUCT.fetchDeleteProduct
   */
  fetchDeleteProduct (params, data) {
    return axios.post(`${SYS_APP_PATH}/product/delete`, data, { params }).then(res => res.data)
  }
}
