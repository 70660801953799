import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 租户管理员-平台下可用产品列表
   * @param params
   * @returns {any} $sysappApi.TENANT.fetchGetTenantConfigProduct
   */
  fetchGetTenantConfigProduct (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/config/product`, { params }).then(res => res.data)
  },
  /**
   * 租户管理员-平台下可用产品机构列表
   * @param params
   * @returns {any} $sysappApi.TENANT.fetchGetTenantConfigMerchant
   */
  fetchGetTenantConfigMerchant (params) {
    return axios.get(`${SYS_APP_PATH}/tenant/config/merchant`, { params }).then(res => res.data)
  },
  /**
   * 租户管理员-平台产品关联机构
   * @param params
   * @param data
   * @returns {any} $sysappApi.TENANT.fetchAddTenantConfigMerchant
   */
  fetchAddTenantConfigMerchant (params, data) {
    return axios.post(`${SYS_APP_PATH}/tenant/config/product/merchant/add`, data, { params }).then(res => res.data)
  }
}
