<template>
  <div class="gtm-dynamic-form">
    <el-form :model="ruleForm" ref="ruleForm" label-width="200px" class="gtm-rule-form">
      <el-collapse :default-expand-all="true" icon-text :card="true" v-model="activeNames" @change="handleChange">
        <el-collapse-item
          v-for="dynamicFormItem in dynamicFormList"
          :key="dynamicFormItem.id"
          :title="dynamicFormItem.groupName"
          :name="dynamicFormItem.id"
        >
          <dynamic-filed
            v-for="item in dynamicFormItem.value"
            :key="item.key"
            :dynamic-filed-item="item"
            @filed-value-change="handleFiledValueChange"
          >
          </dynamic-filed>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import { deepClone } from 'web-utils'
import DynamicFiled from './components/DynamicFiled'

export default {
  name: 'GtmDynamicForm',
  components: { DynamicFiled },
  mixins: [],
  props: {
    dynamicFormList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeNames: [],
      ruleForm: {},
      resultFormList: []
    }
  },
  mounted () {
    this.formatRuleForm()
  },
  methods: {
    handleChange () {
    },
    // 初始化表单数据结构
    formatRuleForm () {
      this.dynamicFormList.forEach(dynamicFormItem => {
        dynamicFormItem.value.forEach(item => {
          this.$set(this.ruleForm, item.key, item.value)
        })
        this.activeNames = this.dynamicFormList.map(item => item.id)
      })
      this.resultFormList = deepClone(this.dynamicFormList)
    },
    // 字段值变更时，更新表单数据
    handleFiledValueChange (key, value) {
      this.$set(this.ruleForm, key, value)
      this.resultFormList.forEach(resultFormItem => {
        resultFormItem.value.forEach(item => {
          if (key === item.key) {
            item.value = value
          }
        })
      })
    },
    // 外部触发，主动校验表单规则
    validateForm () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('validate-success', this.ruleForm, this.resultFormList)
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss">
.demo-page {
}
</style>
