import axios from 'axios'
import { DATA_APP_PATH } from '../base-url'

export default {
  /**
   * 营收数据趋势分析
   * @param data
   * @returns {*} DATA_STATISTIC.fetchOperationData
   */
  fetchOperationData (params, data) {
    return axios.post(`${DATA_APP_PATH}/chartStatistic/operationData`, data, { params }).then(res => res.data)
  },
  /**
   * 业务数据（保额及开函数量）趋势分析
   * @param data
   * @returns {*} DATA_STATISTIC.fetchBusinessData
   */
  fetchBusinessData (params, data) {
    return axios.post(`${DATA_APP_PATH}/chartStatistic/businessData`, data, { params }).then(res => res.data)
  },
  /**
   * 金融机构分析
   * @param data
   * @returns {*} DATA_STATISTIC.fetchMerchantData
   */
  fetchMerchantData (params, data) {
    return axios.post(`${DATA_APP_PATH}/chartStatistic/merchantData`, data, { params }).then(res => res.data)
  },
  /**
   * 全国（全省）产值分析
   * @param data
   * @returns {*} DATA_STATISTIC.fetchMapData
   */
  fetchMapData (params, data) {
    return axios.post(`${DATA_APP_PATH}/chartStatistic/mapData`, data, { params }).then(res => res.data)
  },
  /**
   * 产值排行
   * @param data
   * @returns {*} DATA_STATISTIC.fetchOutputRank
   */
  fetchOutputRank (params, data) {
    return axios.post(`${DATA_APP_PATH}/chartStatistic/outputRank`, data, { params }).then(res => res.data)
  }

}
