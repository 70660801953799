import rootMethods from './root-methods'
import rootState from './root-state'
import rootConst from './root-const'

export { rootMethods, rootState, rootConst }

export default {
  install (Vue) {
    Vue.prototype.$rootMethods = rootMethods
    Vue.prototype.$rootState = rootState
    Vue.prototype.$rootConst = rootConst
  }
}
