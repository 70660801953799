<template>
  <el-main class="gtm-content">
    <div class="gtm-content__main" :class="showFooter ? 'gtm-content__main--has-footer' : ''">
      <slot></slot>
    </div>
    <div v-if="showFooter" class="gtm-content__footer">
      <slot name="footer"></slot>
    </div>
  </el-main>
</template>

<script>
export default {
  name: 'GtmContent',
  props: {
    showFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
