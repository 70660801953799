import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'

export default {
  /**
   * 保函验真
   * @param params
   * @returns {*} GUARANTEE_API.fetchGuaranteeVerify
   */
  fetchGuaranteeVerify (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/guarantee/verify`, data, { params }).then(res => res.data)
  }
}
