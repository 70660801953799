import tenantManager from './tenant-manager'
import tenantConfig from './tenant-config'
import tenantEffective from './tenant-effective'
import tenantProduct from './tenant-product'

export default {
  ...tenantManager,
  ...tenantConfig,
  ...tenantEffective,
  ...tenantProduct
}
