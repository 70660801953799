import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'

export default {
  /**
   * 保函申请管理接口-根据applyNo获取申请信息
   * @param params
   * @returns {*} GUARANTEE_APPLY.fetchGetGuaranteeApplyInfo
   */
  fetchGetGuaranteeApplyInfo (applyNo) {
    return axios.get(`${GUARANTEE_APP_PATH}/guaranteeApply/getApplyInfo/${applyNo}`)
      .then(res => res.data)
  },
  /**
 * 通过交易平台编码，项目编号和标段编号获取项目信息
 * @param params
 * @returns {*}
 */
  fetchGetTradeInfo (params) {
    return axios.get(`${GUARANTEE_APP_PATH}/guaranteeApply/getApplyInfo`, { params })
      .then(res => res.data)
  }
}
