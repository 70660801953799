import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'
export default {
  /**
   * 保存退保信息
   * @param params
   * @returns {*} SURRENDER.fetchUpdateSurrenderInfoSave
   */
  fetchUpdateSurrenderSave (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/surrender/save`, data, { params }).then(res => res.data)
  },
  /**
   * 提交退保信息
   * @param params
   * @returns {*} SURRENDER.fetchUpdateSurrenderInfoSubmit
   */
  fetchUpdateSurrenderInfoSubmit (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/surrender/apply`, data, { params }).then(res => res.data)
  },
  /**
   * 查询退保结果
   * @param params
   * @returns {*} SURRENDER.fetchRequestSurrenderInfoByOrderNo
   */
  fetchRequestSurrenderInfoByOrderNo (params) {
    return axios.get(`${GUARANTEE_APP_PATH}/surrender/result`, { params }).then(res => res.data)
  },
  /**
   * 重新退保
   * @param params
   * @returns {*} SURRENDER.fetchUpdateSurrenderInfoSubmitAgain
   */
  fetchUpdateSurrenderInfoSubmitAgain (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/surrender/reApply`, data, { params }).then(res => res.data)
  }
}
