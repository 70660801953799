// 校验保函申请时的资格
import APP_CONTEXT from '../app-context/index'
import rootState from '../../root-state'

const validateGuaranteeApply = (needEnterpriseAuth = true) => {
  return new Promise((resolve, reject) => {
    APP_CONTEXT.initAppContext('noProduct')
      .finally(() => {
        console.log('【butil-guarantee】校验保函申请资格...', rootState)
        if (!rootState.userInfo.userId) {
          resolve({
            code: 'NOT_LOGIN',
            message: '您尚未登录！'
          })
        } else if (['R_LOCAL_ADMIN', 'R_TENDER_AGENT'].includes(rootState.indexInfo.identify.roleCode)) {
          resolve({
            code: 'IS_TENDER_AGENT',
            message: '当前身份不允许进行业务申请，如有疑问请联系平台客服。'
          })
        } else {
          console.log(rootState.userInfo.authResult)
          if (rootState.userInfo.authResult === '0') {
            resolve({
              code: 'NOT_USER_AUTH',
              message: '温馨提示，您尚未完成“个人实名认证”，请先完成实名认证，并完善信息，以便进行后续服务申请！'
            })
          } else if (needEnterpriseAuth && (!rootState.userInfo.enterpriseAuthResult || rootState.userInfo.enterpriseAuthResult === '0')) {
            resolve({
              code: 'NOT_ENTERPRISE_AUTH',
              message: '温馨提示，您尚未完成“企业实名认证”，请先完成实名认证，并完善信息，以便进行后续服务申请！'
            })
          } else if (rootState.userInfo.authResult === '1') {
            resolve({
              code: 'PASS',
              message: '',
              data: rootState
            })
          }
        }
      })
  })
}

// 校验项目新增时的资格
const validateProjectAdd = rootState => {
  console.log('【butil-project】校验新增项目资格...', rootState)
  if (!rootState.userInfo.userId) {
    return {
      code: 'NOT_LOGIN',
      message: '您尚未登录！'
    }
  } else if (!['R_LOCAL_ADMIN', 'R_TENDER_AGENT'].includes(rootState.indexInfo.identify.roleCode)) {
    return {
      code: 'IS_USER_AGENT',
      message: '当前身份不允许进行项目新增，如有疑问请联系平台客服。'
    }
  } else {
    console.log(rootState.userInfo.authResult)
    if (rootState.userInfo.authResult === '0') {
      return {
        code: 'NOT_USER_AUTH',
        message: '温馨提示，您尚未完成“个人实名认证”，请先完成实名认证，并完善信息，以便进行后续服务申请！'
      }
    } else if (rootState.userInfo.authResult === '1') {
      return {
        code: 'PASS',
        message: ''
      }
    }
  }
}

export default {
  validateGuaranteeApply,
  validateProjectAdd
}

export { validateGuaranteeApply, validateProjectAdd }
