<template>
  <el-container class="gtm-business-layout" direction="vertical">
    <slot></slot>
  </el-container>
</template>

<script>
export default {
  name: 'GtmBusinessLayout'
}
</script>
