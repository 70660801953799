import { Container, Footer, Main } from 'element-gui'

import './style/index.scss'
import GTMBusinessLayout from './GTMBusinessLayout'
import GTMBusinessFooter from './GTMBusinessFooter'
import GTMBusinessContent from './GTMBusinessContent'

GTMBusinessLayout.install = function (Vue) {
  Vue.use(Container)
  Vue.component(GTMBusinessLayout.name, GTMBusinessLayout)
}
GTMBusinessFooter.install = function (Vue) {
  Vue.use(Footer)
  Vue.component(GTMBusinessFooter.name, GTMBusinessFooter)
}
GTMBusinessContent.install = function (Vue) {
  Vue.use(Main)
  Vue.component(GTMBusinessContent.name, GTMBusinessContent)
}

export default {
  GTMBusinessLayout,
  GTMBusinessFooter,
  GTMBusinessContent
}
