import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 场景模板保存
   * @param params
   * @param data
   * @returns {*} $developmentApi.BUSINESS.fetchUpdateBusinessTemplate
   */
  fetchUpdateBusinessTemplate (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/business/templateSave`, data, { params }).then(res => res.data)
  }
}
