<template>
  <div class="gtm-base__home-skeleton-page">
    <div class="skeleton-content">
      <!--      <img-->
      <!--        v-if="skeletonUrl === 'portal/home'"-->
      <!--        src="../../../assets/images/skeleton/home.jpg"-->
      <!--      />-->
      <!--      <img-->
      <!--        v-if="skeletonUrl === 'guarantee-web/institutionList'"-->
      <!--        src="../../../assets/images/skeleton/institutionList.jpg"-->
      <!--      />-->
      <!--      <img-->
      <!--        v-if="skeletonUrl === 'portal/about'"-->
      <!--        src="../../../assets/images/skeleton/about.jpg"-->
      <!--      />-->
      <gtm-loading :show="true"></gtm-loading>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeSkeletonPage',
  computed: {
    ...mapState(['rootFullPath', 'defaultMenuInfo']),
    skeletonUrl () {
      if (this.rootFullPath === 'redirection') {
        return this.defaultMenuInfo.defaultUrl.split('/platform/')[1]
      } else {
        return this.rootFullPath
      }
    },
    showGtmLoading () {
      return !['portal/home', 'guarantee-web/institutionList', 'portal/about'].includes(this.rootFullPath)
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.gtm-base__home-skeleton-page {
  width: 100%;

  .skeleton-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .banner {
      width: 100%;
      height: 620px;
    }

    .sub-title {
      line-height: 30px;
      display: flex;
      align-items: center;
      width: 324px;
      margin: 15px auto;

      .bar {
        height: 14px;
        margin: 0px 20px;
      }
    }
  }
}
</style>
