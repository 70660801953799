export const GUARANTEE_PATH = '/gtm/api/guarantee'

export const GUARANTEE_APP_PATH = '/gtm/api/guarantee-app'

export const BASE_APP_PATH = '/gtm/api/base-app'

export const DEVELOPMENT_PATH = '/gtm/api/development-service'

export const SYSAPP_PATH = '/gtm/api/sysapp'

export const PROJECT_APP_PATH = '/gtm/api/project-app'

export const SYS_APP_PATH = '/gtm/api/sys-app'

export const DATA_APP_PATH = '/gtm/api/data-app'
