<template>
  <div class="gtm-loading-box">
    <div class="loading-box" v-if="visible">
      <div class="circle">
        <img style="width: 100%" src="../../assets/image/gtmLoading/loading.png" alt=""/>
      </div>
      <div class="message">{{ message }}</div>
    </div>
    <div class="content-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GtmLoadingBox',
  props: {
    show: Boolean,
    message: {
      type: String,
      default: '即将呈现，请稍后...'
    }
  },
  computed: {
    visible: {
      get () {
        return this.show
      },
      set () {
        this.$emit('update:show', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.gtm-loading-box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //top: 0;
  //left: 0;

  @keyframes rotate-clockwise {
    0% {
      transform: rotate(0deg); /* 初始状态，未旋转 */
    }
    100% {
      transform: rotate(360deg); /* 结束状态，旋转360度，完成一圈 */
    }
  }

  .loading-box {
    z-index: 999;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 10px 26px 0px rgba(3, 44, 96, 0.15);
    justify-content: center;

    .circle {
      width: 34px;
      height: 34px;
      animation: rotate-clockwise 1.6s linear infinite;
    }

    .message {
      margin-top: 12px;
      width: 124px;
      height: 22px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      text-align: center;
      font-style: normal;
    }
  }

  .content-box {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
