import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'

export default {
  /**
   * 机构客户端实现列表
   * @param params
   * @returns {*} MERCHANT.fetchGetGuaranteeMerchantList
   */
  fetchGetGuaranteeMerchantList (params) {
    return axios.get(`${GUARANTEE_APP_PATH}/client/merchant`, { params }).then(res => res.data)
  }
}
