import { getCookie } from 'web-utils'

/**
 * 经济公司切换域名-反向切换逻辑
 * @param to
 * @param from
 * @param next
 */
const changeHost = (to, from) => {
  const tenantHost = sessionStorage.getItem('TENANT_DOMAIN')
  const tenantOriginDomain = sessionStorage.getItem('TENANT_ORIGIN_DOMAIN')
  const applyPath = '/platform/guarantee-web/apply'
  if (to.path !== applyPath && from.path === applyPath) {
    console.log(window.location.hostname, tenantHost)
    if (window.location.hostname !== tenantHost) {
      let arr = to.path.split('/')
      const pageName = arr[arr.length - 1]
      let url = `${tenantOriginDomain}/gtm/web${to.fullPath}`
      url = url.replace(to.path, '/platform/redirection')
      if (url.includes('?')) {
        url = `${url}&pageName=${pageName}`
      } else {
        url = `${url}?pageName=${pageName}`
      }
      const token = getCookie('SESSION_TOKEN')
      if (!url.includes('token') && token) {
        url = `${url}&token=${token}`
      }
      return url
    }
  }
}

export {
  changeHost
}
