import { Divider } from 'element-gui'

import GtmDividerTitle from './GtmDividerTitle'

GtmDividerTitle.install = function (Vue) {
  Vue.use(Divider)
  Vue.component(GtmDividerTitle.name, GtmDividerTitle)
}

export default {
  GtmDividerTitle
}
