<template>
  <div class="gtm-dynamic-filed">
    <el-form-item
      :key="formItem.key"
      :span="12"
      :label="formItem.name"
      :prop="formItem.key"
      :rules="formatRules(formItem)"
    >
      <div v-if="formItem.type === 'STRING'">
        <el-input v-model="formItem.value" :placeholder="`请填写${formItem.name}`" @change="valueChange"></el-input>
      </div>
      <div v-if="formItem.type === 'TEXT'">
        <el-input type="textarea" v-model="formItem.value" placeholder="请填写" @change="valueChange"></el-input>
      </div>
      <div v-if="formItem.type === 'NUMBER'">
        <el-input
          v-model.trim="formItem.value" placeholder="请填写数字" @change="valueChange"
        ></el-input>
      </div>
      <div v-if="formItem.type === 'DATE'">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="formItem.value"
          :placeholder="`请选择${formItem.name}`"
          type="datetime"
          @change="valueChange"
        >
        </el-date-picker>
      </div>
      <div v-if="formItem.type === 'BOOLEAN'">
        <el-radio-group v-model="formItem.value" @change="valueChange">
          <el-radio
            v-for="item in stringToArray(formItem.typeValue)" :key="item.value" :label="item.value"
          >{{ item.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div v-if="formItem.type === 'ENUM_SELECT'">
        <el-select @change="valueChange" v-model="formItem.value" placeholder="请选择活动区域">
          <el-option
            v-for="item in stringToArray(formItem.typeValue)"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div v-if="formItem.type === 'ENUM_CHECKBOX'">
        <el-checkbox-group @change="valueChange" v-model="formItem.value">
          <el-checkbox
            v-for="item in stringToArray(formItem.typeValue)" :label="item.value" :key="item.value"
          >{{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </el-form-item>
  </div>
</template>

<script>
var checkNumber = (rule, value, callback) => {
  const num = parseFloat(value)
  if (!(!isNaN(num) && String(num) === value.trim())) {
    callback(new Error('请输入数字值'))
  } else {
    if (value > 999999999.99) {
      callback(new Error('数字值必须小于999999999.99'))
    } else {
      callback()
    }
  }
}
export default {
  name: 'GtmDynamicFiled',
  props: {
    dynamicFiledItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      formItem: {}
    }
  },
  mounted () {
    this.formItem = { ...this.dynamicFiledItem }
    if (this.dynamicFiledItem.type === 'NUMBER') {
      this.formItem.value = Number(this.dynamicFiledItem.value)
    }
    if (this.dynamicFiledItem.type === 'ENUM_CHECKBOX') {
      this.formItem.value = JSON.parse(this.dynamicFiledItem.value)
    }
  },
  methods: {
    formatRules (formItem) {
      let rules = [
        { required: formItem.required, message: `请输入${formItem.name}`, trigger: 'blur' }
      ]
      if (formItem.type === 'NUMBER') {
        rules.push({ validator: checkNumber, trigger: 'blur' })
      }
      return rules
    },
    stringToArray (string) {
      if (string) {
        return JSON.parse(string)
      } else {
        return []
      }
    },
    valueChange (value) {
      if (this.dynamicFiledItem.type === 'ENUM_CHECKBOX') {
        value = JSON.stringify(value)
      }
      this.$emit('filed-value-change', this.formItem.key, value)
    }
  }
}
</script>
<style lang="scss">
.gtm-dynamic-filed {
}
</style>
