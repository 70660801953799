<template>
  <div class="gtm-business-footCom">
    <div class="main-content">
      <div class="detail-top">
        <div class="contact-overview">
          <div class="left">
            <div v-for="(contactItem, index) in contactList" :key="index" class="box">
              <div class="title">{{ contactItem.typeName }}</div>
              <div
                class="address"
                v-for="(linkNameItem, index) in contactItem.linkNameList"
                :key="index"
                @click="onLinkTo(linkNameItem.code, linkNameItem.url)"
              >
                {{ linkNameItem.name }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="telephone">
              <div class="hot-line">
                <div class="title">全国服务热线</div>
                <div class="number">{{ hotLine }}</div>
              </div>
              <div class="hot-line">
                <div class="title">合作咨询</div>
                <div class="number">{{ consultPhone }}</div>
                <div class="comments">7x24小时专属客服</div>
              </div>
            </div>
            <div class="QRImg">
              <div class="QRImg_box">
                <img src="./image/oa.png"/>
                <div class="remark">关注公众号</div>
              </div>
              <div class="QRImg_box">
                <img src="./image/chat.png"/>
                <div class="remark">业务交流群</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-bottom">
        <div style="display: flex">
          <span class="text" @click="onOpenPage('agreement')">法律声明</span>
          <span class="bar"></span>
          <span class="text" @click="onOpenPage('privacy')">隐私条款</span>
          <span class="bar"></span>
          <span class="text" @click="onOpenPage()">意见反馈</span>
        </div>
        <div class="summary">
          <span>©Glodon Company Limited 1998-2020 All Rights Reserved. 广联达科技股份有限公司</span>
          <img src="./image/lawIcon.png" style="width: 14px; height: 15px; margin-left: 40px"/>
          <span class="summary-span">京ICP备10021606号-1 京公网安备11010802021391号</span>
        </div>
        <div>主办单位：广联达科技股份有限公司</div>
      </div>
    </div>
    <GtmConsultDialog :visible.sync="showConsultDialog"/>
  </div>
</template>

<script>
import GtmConsultDialog from '../../gtm-consult-dialog/GtmConsultDialog'
import { rootState } from '@gtm-butils'

export default {
  name: 'FootCom',
  components: { GtmConsultDialog },
  mixins: [],
  props: {},
  data () {
    return {
      productCodeList: [],
      contactList: [
        {
          typeName: '保函申请',
          linkNameList: [
            {
              name: '投标保函',
              code: 'TENDER'
            },
            {
              name: '履约保函',
              code: 'PERFORMANCE'
            },
            {
              name: '预付款保函',
              code: 'PREPAYMENTS'
            },
            {
              name: '农民工工资支付保函',
              code: 'SALARY'
            },
            {
              name: '工程质量保函',
              code: 'PROJECTQUALITY'
            },
            {
              name: '工程款支付保函',
              code: 'PAYMENTS'
            }
          ]
        },
        {
          typeName: '公司链接',
          linkNameList: [
            {
              name: '广联达科技股份有限公司',
              code: 'glodonCompany',
              url: 'https://www.glodon.com/'
            },
            {
              name: '广联达投标服务',
              code: 'toubiao',
              url: 'https://huiyizhao.glodon.com/website/#/home'
            },
            {
              name: '广联达清标',
              code: 'qingbiao',
              url: 'https://vbp.glodon.com/home/index'
            }
          ]
        },
        {
          typeName: '友情链接',
          linkNameList: [
            {
              name: '西藏自治区公共资源交易网',
              code: 'xizangjiaoyi',
              url: 'http://ggzy.xizang.gov.cn/'
            },
            {
              name: '贵州省公共资源交易网',
              code: 'guizhousheng',
              url: 'https://ggzy.guizhou.gov.cn/'
            },
            {
              name: '北京市公共资源交易服务平台',
              code: 'beijingjiaoyi',
              url: 'https://ggzyfw.beijing.gov.cn/'
            }
            // {
            //   name: '辽宁省公共资源交易中心',
            //   code: 'liaoningjiaoyi',
            //   url: 'http://www.lnbm.gov.cn/ggzyjyzx/'
            // },
            // {
            //   name: '北京市公共资源交易服务平台',
            //   code: 'beijingjiaoyi',
            //   url: 'https://ggzyfw.beijing.gov.cn/'
            // },
            // {
            //   name: '浙江省公共资源交易中心',
            //   code: 'zhejiangjiaoyi',
            //   url: 'http://zjpubservice.zjzwfw.gov.cn/'
            // }
          ]
        },
        {
          typeName: '关于我们',
          linkNameList: [
            {
              name: '公司介绍',
              code: 'introduction'
            },
            // {
            //   name: '新闻资讯',
            //   code: 'news'
            // },
            {
              name: '联系我们',
              code: 'contact'
            }
          ]
        }
      ],
      hotLine: '13127895979',
      consultPhone: '15011348980',
      showConsultDialog: false
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    const productList = rootState.productList
    this.productCodeList = productList.map(item => {
      return item.productCode
    })
  },
  methods: {
    onOpenPage (path) {
      if (path) {
        this.$rootMethods.TOOLS.qiankunRouterResolve({
          appName: '',
          path
        })
      } else {
        this.showConsultDialog = true
      }
    },
    onLinkTo (code, url) {
      let productNumberList = [
        'TENDER',
        'PERFORMANCE',
        'PREPAYMENTS',
        'SALARY',
        'PROJECTQUALITY',
        'PAYMENTS',
        'contact'
      ]
      let friendUrlList = ['glodonCompany', 'toubiao', 'qingbiao', 'xizangjiaoyi', 'guizhousheng', 'beijingjiaoyi']
      if (this.productCodeList.includes(code)) {
        this.$rootMethods.TOOLS.qiankunRouterPush({
          appName: 'guarantee-web',
          path: '/institutionList',
          query: { productCode: code }
        })
      } else if (friendUrlList.includes(code)) {
        window.open(url, '_blank')
      } else if (code === 'introduction') {
        this.$rootMethods.TOOLS.qiankunRouterPush({
          appName: 'portal',
          path: '/about'
        })
      } else if (productNumberList.includes(code)) {
        this.showConsultDialog = true
      }
    }
  }
}
</script>
<style lang="scss">
.gtm-business-footCom {
  color: #ffffff;
  width: 100%;
  // height: 410px;
  background-color: #004ac9;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 20px;

  .main-content {
    display: flex;
    width: 1440px;
    flex-direction: column;

    .detail-top {
      display: flex;
      margin-top: 50px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.5);

      .contact-overview {
        display: flex;

        .left {
          display: flex;

          .box {
            display: flex;
            flex-direction: column;

            &:not(:first-of-type) {
              margin-left: 90px;
            }

            &:last-of-type {
              padding-right: 80px;
              border-right: 2px solid;
              border-image-source: linear-gradient(
                  180deg,
                  rgba(201, 201, 201, 0) 0%,
                  rgba(176, 176, 176, 0.5) 52%,
                  rgba(255, 255, 255, 0) 100%
              );
              border-image-slice: 1;
            }

            .title {
              font-size: 16px;
              line-height: 21px;
              text-align: left;
              margin-bottom: 5px;
            }

            .address {
              font-size: 14px;
              line-height: 22px;
              margin-top: 14px;
              color: rgba(255, 255, 255, 0.8);
              cursor: pointer;

              &:hover {
                color: #fff;
                font-weight: bold;
              }
            }
          }
        }

        .right {
          display: flex;
          margin-left: 66px;

          .telephone {
            .hot-line {
              &:not(:first-of-type) {
                margin-top: 24px;
              }

              .title {
                font-size: 16px;
                line-height: 22px;
              }

              .number {
                margin-top: 14px;
                font-size: 24px;
                line-height: 31px;
              }

              .comments {
                margin-top: 10px;
                margin-left: 4px;
                font-size: 12px;
                line-height: 16px;
              }
            }
          }

          .QRImg {
            display: flex;
            margin-left: 97px;

            &_box {
              &:not(:first-of-type) {
                margin-left: 50px;
              }

              img {
                width: 100px;
                height: 100px;
              }

              .remark {
                margin-top: 12px;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .detail-bottom {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 22px;
      margin-top: 20px;

      div {
        .text {
          &:hover {
            cursor: pointer;
            font-weight: bold;
          }
        }

        .bar {
          height: 10px;
          border-left: 1px solid #fff;
          margin: 6px 15px 0px;
        }
      }

      div:not(:first-of-type) {
        margin-top: 10px;
      }

      .summary {
        display: flex;
        align-items: center;

        .summary-span {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
