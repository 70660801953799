import { getOSAndVersion, getBrowserInfoAndScreenResolution } from 'web-utils'
import { trackingPoolMap } from './tracking-pool'

// 获取操作系统和版本号
const os = getOSAndVersion()

// 获取浏览器类型、版本号、分辨率
const browserInfo = getBrowserInfoAndScreenResolution()
const browserName = browserInfo.browser.name
const browserVersion = browserInfo.browser.version

// 获取企业信息
const userInfo = JSON.parse(localStorage.getItem('portal_root_userInfo'))
const enterpriseName = userInfo?.enterpriseName || ''
const enterpriseCode = userInfo?.enterpriseCode || ''
const enterprise = enterpriseName && enterpriseCode ? `${enterpriseName}&${enterpriseCode}` : ''

const tenantInfo = JSON.parse(sessionStorage.getItem('TENANT_INFO')) || {}

// 初始化系统值
const initTrackData = (fncode) => {
  let trackData = {
    pcode: process.env.VUE_APP_ENV === 'production' ? '124482' : '151076', // 产品ID，生产：124482  测试：151076
    fncode: fncode, // 埋点功能码
    fnname: trackingPoolMap[fncode].fnname, // 功能名 例如（进入_履约_选择金融机构_PV）
    fngroup: trackingPoolMap[fncode].fngroup, // 功能分组 例如（贵州履约——选择金融机构）
    ver: '3.0', // 版本号 需要产品定义这个版本号（之前是1.0）
    gid: userInfo?.tel || localStorage.getItem('CUSTOMER_IP') || 'customer', // 广联云ID 企业ID（统一社会信用代码）
    tenantid: tenantInfo?.tenantName || '未知租户',
    device_id: '/',
    sessionid: '/',
    query: JSON.stringify({
      enterprise: enterprise,
      enterprisename: enterpriseName,
      enterprisecode: enterpriseCode,
      browser: `${browserName}${browserVersion}`,
      resolution: `${browserInfo.resolution.width}x${browserInfo.resolution.height}`,
      system: `${os.os}${os.version}`,
      userAgent: navigator.userAgent
    })
  }
  return trackData
}

// 发送数据到灯塔
const sendTrackData = (fncode) => {
  if (userInfo?.testData) {
    console.log('【butil-data-tracking】测试账号，无需发送埋点信息')
    return
  }
  // 在浏览器中设置触发的时间戳
  let trackingTriggerTime = localStorage.getItem('tracking_trigger_time')
  let trackInfo = trackingTriggerTime ? JSON.parse(trackingTriggerTime) : {}
  trackInfo[fncode] = new Date().getTime()
  localStorage.setItem('tracking_trigger_time', JSON.stringify(trackInfo))

  const data = initTrackData(fncode)
  console.log('【butil-data-tracking】发送埋点数据...', data)
  window.Report.track(data)
}

export default {
  sendTrackData
}

export {
  sendTrackData
}
