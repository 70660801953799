import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 业务侧_机构列表
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetMerchantBusinessList
   */
  fetchGetMerchantBusinessList (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/business/list`, data, { params }).then(res => res.data)
  },
  /**
   * 业务侧_通过机构编码查询机构信息
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetMerchantBusinessByMerchantCode
   */
  fetchGetMerchantBusinessByMerchantCode (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/business/find`, data, { params }).then(res => res.data)
  },
  /**
   * 获取已上传机构所需附件数据
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetMerchantAttachInfoList
   */
  fetchGetMerchantAttachInfoList (params) {
    return axios.get(`${SYS_APP_PATH}merchant/business/queryMerchantAttaches`, { params }).then(res => res.data)
  },
  /**
   * 业务侧_获取保函模板
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetTemplate
   */
  fetchGetTemplate (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/business/template`, data, { params }).then(res => res.data)
  },
  /**
   * 业务侧查询平台的所属地区
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetBusinessArea
   */
  fetchGetBusinessArea (params) {
    return axios.get(`${SYS_APP_PATH}/merchant/business/findArea`, { params }).then(res => res.data)
  },
  /**
   * 获取所有业务机构名称
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetBusinessAllName
   */
  fetchGetBusinessAllName (params, data) {
    return axios.get(`${SYS_APP_PATH}/merchant/business/allName`, { params }).then(res => res.data)
  }
}
