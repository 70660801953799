const baseMenu = [
  {
    name: '用户注册',
    routeName: 'Register',
    path: 'register',
    appName: '',
    auth: false,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '注册成功',
    routeName: 'RegisterSuccess',
    path: 'registerSuccess',
    appName: '',
    auth: false,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '用户协议',
    routeName: 'Agreement',
    path: 'agreement',
    appName: '',
    auth: false,
    aside: false,
    header: false,
    headerMenu: false
  },
  {
    name: '隐私政策',
    routeName: 'Privacy',
    path: 'privacy',
    appName: '',
    auth: false,
    aside: false,
    header: false,
    headerMenu: false
  },
  {
    name: '登录',
    routeName: 'GtmLogin',
    path: 'gtmLogin',
    appName: '',
    auth: false,
    aside: false,
    header: false,
    headerMenu: false
  },
  {
    name: '登录',
    routeName: 'Login',
    path: 'login',
    appName: '',
    auth: false,
    aside: false,
    header: false,
    headerMenu: false
  },
  {
    name: '404页面',
    routeName: 'NotFound',
    path: 'notFound',
    appName: '',
    auth: false,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '跳转空白页',
    routeName: 'Redirection',
    isActive: false,
    path: 'redirection',
    appName: '',
    auth: false,
    aside: false,
    header: true,
    headerMenu: false
  },
  {
    name: '用户选择角色',
    routeName: 'UserRole',
    path: 'userRole',
    appName: '',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  }
]

export { baseMenu }
