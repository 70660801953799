import axios from 'axios'
import { DATA_APP_PATH } from '../base-url'

export default {
  /**
   * 累计服务企业数（家）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchEnterpriseCount
   */
  fetchEnterpriseCount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/enterpriseCount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计开函数量（张）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchGuaranteeCount
   */
  fetchGuaranteeCount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/guaranteeCount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计保额（万元）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchTenderBondAmount
   */
  fetchTenderBondAmount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/tenderBondAmount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计退保数量（单）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchSurrenderCount
   */
  fetchSurrenderCount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/surrenderCount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计索赔数量（单）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchClaimCount
   */
  fetchClaimCount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/claimCount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计营收金额（万元）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchProfitAmount
   */
  fetchProfitAmount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/profitAmount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计保费金额（万元）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchGuaranteeFeeAmount
   */
  fetchGuaranteeFeeAmount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/guaranteeFeeAmount`, data, { params }).then(res => res.data)
  },
  /**
   * 累计运营保费金额（万元）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchShareGuaranteeFeeAmount
   */
  fetchShareGuaranteeFeeAmount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/shareGuaranteeFeeAmount`, data, { params }).then(res => res.data)
  },
  /**
   * 运营累计开函数量（张）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchShareGuaranteeCount
   */
  fetchShareGuaranteeCount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/shareGuaranteeCount`, data, { params }).then(res => res.data)
  },
  /**
   * 客单价（元）
   * @param data
   * @returns {*} DATA_STATISTIC.fetchCustomerAverageAmount
   */
  fetchCustomerAverageAmount (params, data) {
    return axios.post(`${DATA_APP_PATH}/dataStatistic/customerAverageAmount`, data, { params }).then(res => res.data)
  }
}
