import PRODUCT from './product'
import MERCHANT from './merchant/index'
import TENANT from './tenant/index'
import ISSUED from './issued'
import PLATFORM from './platform'
import BROKERAGE from '../sysapp/brokerage'

export default {
  PRODUCT,
  MERCHANT,
  TENANT,
  ISSUED,
  PLATFORM,
  BROKERAGE
}
