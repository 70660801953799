<template>
  <div class="gtm-micro-app__page">
    <!--    <micro-app-->
    <!--      class="gtm-micro-app__development"-->
    <!--      :name="microAppConfig.development.appName"-->
    <!--      :url="microAppConfig.development.url"-->
    <!--      :baseroute="microAppConfig.development.baseRoute"-->
    <!--      :data="rootMicroAppData"-->
    <!--      @created="handleCreate"-->
    <!--      @beforemount="handleBeforeMount"-->
    <!--      @mounted="handleMount"-->
    <!--      @unmount="handleUnmount"-->
    <!--      @error="handleError"-->
    <!--      @datachange="handleDataChange"-->
    <!--    ></micro-app>-->
    <div class="gtm-micro-app__development" id="development"></div>
  </div>
</template>

<script>
import { loadMicroApp } from 'qiankun'
import { mapState } from 'vuex'

export default {
  name: 'Development',
  props: {},
  data () {
    return {
      microApp: null,
      microAppRender: false
    }
  },
  computed: {
    ...mapState(['rootMicroAppData'])
  },
  mounted () {
    if (this.$store.state.activeMicroApp.instance && this.$store.state.activeMicroApp.appName === 'development') {
      this.$store.state.activeMicroApp.instance.unmount()
    }
    this.microApp = loadMicroApp({
      name: 'development',
      entry: `${window.location.origin}/development`,
      container: '#development',
      activeRule: '/gtm/web/platform/development',
      props: { data: this.rootMicroAppData }
    })
    this.$store.state.activeMicroApp = {
      instance: this.microApp,
      appName: 'development'
    }
    console.log('【base-platform】【development】子应用执行mounted方法')
  },
  beforeRouteLeave (to, from, next) {
    if (this.microApp !== null) {
      this.microApp.unmount()
    }
    next()
  }
}
</script>

<style lang="scss">
.gtm-micro-app__page {
  width: 100%;
  height: 100%;

  .gtm-micro-app__development {
    height: 100%;
  }

  #__qiankun_microapp_wrapper_for_development__ {
    height: 100%;
  }

  #__qiankun_microapp_wrapper_for_development_1__ {
    height: 100%;
  }
}
</style>
