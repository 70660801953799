<!--
  @name: 立即咨询
  @description: 立即咨询
  @author: 马群
  @time: 2024-04-22 10:32:09
-->
<template>
  <el-dialog
    width="820px"
    title="立即咨询"
    :visible.sync="dialogTableVisible"
    @open="onDialogOpen"
    custom-class="gtm-consult-dialog"
  >
    <el-alert
      :closable="false"
      :title="'专业人员7X24小时开函服务热线：13127895979，提交完成后将在30分钟内与您联系'"
      :type="'warning'"
      show-icon
      style="margin: 20px 20px 0px 20px; width: 780px"
    >
    </el-alert>
    <div class="formClass" style="padding: 20px 20px 20px">
      <el-form ref="ruleForm" :model="form" label-width="110px" :rules="rules">
        <el-form-item label="产品类型" :span="24" prop="productType">
          <el-select v-model="form.productType" placeholder="请选择产品类型">
            <el-option v-for="item in productOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="您的姓名" :span="24" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的姓名" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="联系电话" :span="24" prop="mobile">
          <el-input
            v-model="form.mobile"
            maxlength="15"
            placeholder="请输入联系人手机号或座机号码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :span="24" prop="desc">
          <el-input v-model="form.desc" type="textarea" maxlength="200" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onConfirmClick">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { gtmDebounce } from 'web-utils'
import { baseAppApi } from '@gtm-api'

let mobilePass = (rule, value, callback) => {
  if (rule.required && value === '') {
    callback(new Error('请填写手机号码或座机号码'))
  } else {
    callback()
  }
}
export default {
  name: 'GtmConsultDialog',
  components: {},
  mixins: [],
  props: {
    visible: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      productOptions: [
        {
          value: 'TENDER',
          label: '投标保函'
        },
        {
          value: 'PERFORMANCE',
          label: '履约保函'
        },
        {
          value: 'PREPAYMENTS',
          label: '预付款保函'
        },
        {
          value: 'SALARY',
          label: '农民工工资支付保函'
        },
        {
          value: 'PROJECTQUALITY',
          label: '工程质量保函'
        },
        {
          value: 'PAYMENTS',
          label: '工程款支付保函'
        },
        {
          value: 'LITIGATION',
          label: '诉讼保函'
        }
      ],
      form: {
        productType: '',
        name: '', // 名字
        mobile: '', // 手机号码
        desc: ''
      },
      rules: {
        mobile: [{ validator: mobilePass, required: true, trigger: 'change' }]
      }
    }
  },
  computed: {
    dialogTableVisible: {
      get () {
        return this.visible
      },
      set () {
        this.$emit('update:visible', false)
      }
    }
  },
  methods: {
    onDialogOpen () {
      this.form = {
        productType: '',
        name: '', // 名字
        mobile: '', // 手机号码
        desc: ''
      }
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
    },
    onConfirmClick: gtmDebounce(function () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.requestAddVisitorMessage()
        } else {
          return false
        }
      })
    }, 300),
    requestAddVisitorMessage () {
      let params = {}
      let body = {
        name: this.form.name,
        productType: this.form.productType,
        remark: this.form.desc,
        tel: this.form.mobile
      }
      baseAppApi.VISITOR.fetchVisitorSubmit(params, body)
        .then(() => {
          this.$message.success('提交成功!')
        })
        .finally(() => {
          this.dialogTableVisible = false
        })
    }
  }
}
</script>
<style lang="scss">
.gtm-consult-dialog {
  .el-dialog.el-dialog--center {
    border-radius: 20px;
  }

  .el-dialog__body {
    padding: 0;

    .el-alert {
      padding: 13px 20px;

      .el-alert__title {
        font-size: 16px;
      }
    }
  }

  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
  }

  .el-dialog__footer {
    padding: 10px 20px 20px;
    text-align: right;
    box-sizing: border-box;
    font-size: 0;
    background: #fff;
    border-radius: 0px 0px 2px 2px;

    .el-button--primary {
      border: none;
      color: #fff;
      background-color: $--base-color;

      &:hover {
        background-color: $--hover-color;
      }

      &:active {
        background-color: $--click-color;
      }
    }
  }

  .el-dialog__header {
    padding: 0 20px;
    background-color: $--base-color;
    font-weight: 700;
    border-radius: 2px 2px 0px 0px;
  }

  .el-select {
    width: 100%;
  }
}

.el-message--error {
  border: 1px solid #fd5656 !important;
  border-radius: 0px;
}

.el-message--success {
  border: 1px solid #08a003 !important;
  border-radius: 0px;
}
</style>
