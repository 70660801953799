import FILE from './file'
import USER from './user'
import USER_AUTH from './user-auth'
import ENTERPRISE_AUTH from './enterprise-auth'
import VISITOR from './visitor'

export default {
  FILE,
  USER,
  USER_AUTH,
  ENTERPRISE_AUTH,
  VISITOR
}
