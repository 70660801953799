/**
 * @name: 路由控制
 * @description: 全局的路由控制
 * @author: 马群
 * @time: 2023-11-15 11:07:19
 */
import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../vuex'
import { rootState } from '@gtm-butils'
// 主应用
import Platform from '@/views/platform/Platform'
import { baseRoute } from './base-route'
// 子应用
import Development from '@/views/micro-apps/Development'
import Portal from '@/views/micro-apps/Portal'
import Guarantee from '@/views/micro-apps/Guarantee'
import Backend from '@/views/micro-apps/Backend'
import Bi from '@/views/micro-apps/Bi'
// 菜单信息
import { mixtureMenu } from '@/menu/index'

import { saveAs } from 'file-saver'
import { asBlob } from 'html-docx-js-typescript'

import { changeHost } from './change-host'

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location)
    .catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location)
    .catch(err => err)
}

Vue.use(Router)

const routes = [
  {
    path: '/platform',
    name: 'Base',
    component: Platform,
    children: [
      {
        path: 'development*',
        name: 'development',
        component: Development
      },
      {
        path: 'portal*',
        name: 'portal',
        component: Portal
      },
      {
        path: 'guarantee-web*',
        name: 'guaranteeWeb',
        component: Guarantee
      },
      {
        path: 'backend*',
        name: 'backend',
        component: Backend
      },
      {
        path: 'bi*',
        name: 'bi',
        component: Bi
      },
      ...baseRoute
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: '/gtm/web',
  routes,
  duplicateNavigationPolicy: 'ignore'
})

router.beforeResolve((to, from, next) => {
  let currentHostMenuList = ['Home', 'InstitutionList', 'About']

  // 读取全局配置文件，如果有的话，就用全局的，不然就用默认的
  if (rootState?.tenantConfig?.header?.menuList && rootState?.tenantConfig?.header?.menuList.length > 0) {
    currentHostMenuList = rootState?.tenantConfig?.header?.menuList
  }

  const defaultMenu = mixtureMenu.find(it => it.routeName === currentHostMenuList[0])
  const defaultUrl = `${window.location.origin}/gtm/web/platform/${defaultMenu.appName}${defaultMenu.path}`
  const defaultPath = `/platform/${defaultMenu.appName}${defaultMenu.path}`
  console.log(rootState)
  console.log(`%c当前租户默认菜单：${defaultPath}`, 'color:orangered;font-size:20px;')

  store.state.defaultMenuInfo = {
    defaultMenu,
    defaultUrl,
    defaultPath
  }
  if (from.path !== to.path) {
    NProgress.start()
  }
  store.state.rootRouterInfo = {
    to,
    from
  }
  // toFullPath为即将跳转的子应用的完整路由地址
  const toFullPath = to.path.split('/platform/')[1]
  // 如果跳转的地址不一样，说明需要切换路由并记录当前的路由地址
  if (store.state.rootFullPath !== toFullPath) {
    store.state.rootFullPath = toFullPath
    console.log(`%c当前跳转地址为：${toFullPath}`, 'color:orangered;font-size:20px;')
    // 这里是为了解决一开始获取不到path的问题，使用route获取可能更准
    const toMenu = mixtureMenu.find(it => `${it.appName}${it.path}` === store.state.rootFullPath)
    console.log('【base-platform】【router.js】跳转的菜单信息:', toMenu)
    if (toMenu) {
      // 【经济公司切换域名】- 如果不是本地环境，就切换，本地的话，不切换
      if (process.env.NODE_ENV !== 'development') {
        let url = changeHost(to, from, next)
        if (url) {
          window.location.href = url
          return
        }
      }
      if (toMenu.activePath) {
        store.state.rootActivePath = toMenu.activePath
      } else {
        store.state.rootActivePath = ''
      }
      console.log(store.state.rootActivePath)
      rootState.path = toMenu.path
      // 这个逻辑目前没用，用来兼容空路由情况的，以后可能会换逻辑
      if (rootState.path === '') {
        rootState.path = to.query?.development?.split('/development')[1]
      }
      rootState.appName = toMenu.appName
      store.state.showAside = toMenu?.aside
      store.state.showHeader = toMenu?.header
      if (toMenu?.headerMenu === false) {
        store.state.showHeaderMenu = false
      } else {
        store.state.showHeaderMenu = true
      }
      const showHeaderHomeList = ['Register', 'RegisterSuccess']
      if (showHeaderHomeList.includes(toMenu.routeName)) {
        store.state.showHeaderHome = true
      }
      const showHeaderLoginList = ['Home', 'InstitutionList', 'About']
      if (showHeaderLoginList.includes(toMenu.routeName)) {
        store.state.showHeaderLogin = true
      }
      const hideHeaderUserInfoList = ['UserRole']
      if (hideHeaderUserInfoList.includes(toMenu.routeName)) {
        store.state.showUserInfo = false
      } else {
        store.state.showUserInfo = true
      }
    } else {
      const loginStatus = localStorage.getItem('portal_root_loginStatus')
      if (loginStatus === 'true' || loginStatus === true) {
        if (window.location.origin.includes('open-jg-test.glodon.com')) {
          window.location.href = `${window.location.origin}/gtm/web/platform/login`
        } else {
          window.location.href = defaultUrl
        }
      } else {
        // 目前对外暴露的域名中，只有开放平台跳登录，其他的都应该跳首页
        if (window.location.origin.includes('open-jg-test.glodon.com')) {
          router.push({ name: 'Login' })
        } else {
          router.push({
            path: defaultPath
          })
        }
      }
    }
  } else {
    if (to.path === '/platform/') {
      if (window.location.origin.includes('open-jg-test.glodon.com')) {
        router.push({ name: 'Login' })
      } else {
        router.push({
          path: defaultPath
        })
      }
    } else {
      next()
    }
  }
  next()
})

const basePathName = [
  'Register',
  'RegisterSuccess',
  'Login',
  'Agreement',
  'Privacy',
  'NotFound',
  'GtmLogin',
  'Redirection',
  'UserRole'
]
router.afterEach((to, from, next) => {
  if (basePathName.includes(to.name)) {
    setTimeout(() => {
      NProgress.done()
    }, 500)
  }
  console.log(rootState, 'rootState-------')
  const toMenu = mixtureMenu.find(it => `${it.appName}${it.path}` === store.state.rootFullPath)
  console.log(toMenu)
  if (toMenu?.auth && (rootState?.indexInfo?.code === 500 || !rootState.indexInfo)) {
    store.state.isLoginExpire = true
  }
})

window.addEventListener(
  'message',
  event => {
    if (event.data.action === 'routerAfter') {
      setTimeout(() => {
        NProgress.done()
      }, 500)
      const toMenu = mixtureMenu.find(it => `${it.appName}${it.path}` === store.state.rootFullPath)
      if (toMenu?.auth && rootState?.indexInfo?.code === 500) {
        store.state.isLoginExpire = true
      }
    }
    if (event.data.action === 'interfaceNoAuth') {
      if (!store.state.isLoginExpire) {
        store.state.isLoginExpire = true
      }
    }
    if (event.data.action === 'routerBefore') {
      console.log('【base-platform】【router.js】收到子应用发来的数据:', event.data.data)
    }
    if (event.data.action === 'routerPush') {
      const { appName, path, query } = event.data
      router.push({
        path: `/platform/${appName}${path}`,
        query
      })
    }
    if (event.data.action === 'routerResolve') {
      const { appName, path, query } = event.data
      let { href } = router.resolve({
        path: `/platform/${appName}${path}`,
        query
      })
      window.open(href, '_blank')
    }
    // 委托父应用下载模板
    if (event.data.action === 'downloadFile') {
      const { typeName, content } = event.data
      asBlob(content)
        .then(data => {
          saveAs(data, typeName + '模板.docx')
        })
    }
  },
  false
)

export default router
