import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 获取用户上线申请信息
   * @param applyId
   * @param params
   * @returns {any} $developmentApi.GO_ONLINE.fetchGetOnlineInfoByApplyId
   */
  fetchGetOnlineInfoByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/goOnline/getOnlineInfo/${applyId}`, { params }).then(res => res.data)
  },
  /**
   * 提交上线申请
   * @param params
   * @param data
   * @returns {*} $developmentApi.GO_ONLINE.fetchUpdateGoOnlineSubmit
   */
  fetchUpdateGoOnlineSubmit (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/goOnline/goOnline/submit`, data, { params }).then(res => res.data)
  },
  /**
   * 提交审核
   * @param params
   * @param data
   * @returns {*} $developmentApi.GO_ONLINE.fetchUpdateGoOnlineApprove
   */
  fetchUpdateGoOnlineApprove (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/goOnline/goOnline/approveSubmit`, data, { params }).then(res => res.data)
  },
  /**
   * 查询是否满足上线申请条件
   * @param applyId
   * @param params
   * @returns {any} $developmentApi.GO_ONLINE.fetchGetAllowOnlineByApplyId
   */
  fetchGetAllowOnlineByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/goOnline/isAllowToGoOnline/${applyId}`, { params }).then(res => res.data)
  }
}
