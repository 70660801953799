const biMenu = [
  {
    name: 'BI首页',
    routeName: 'BiHome',
    path: '/biHome',
    appName: 'bi',
    auth: true,
    aside: false,
    header: true,
    headerMenu: false
  }
]

export { biMenu }
