import Login from '@/views/base-business/Login'

export const baseRoute = [
  {
    path: 'register',
    name: 'Register',
    component: () => import('@/views/base-business/register/Register'),
    meta: {
      header: true
    }
  },
  {
    path: 'registerSuccess',
    name: 'RegisterSuccess',
    component: () => import('@/views/base-business/register/RegisterSuccess'),
    meta: {
      header: true
    }
  },
  {
    path: 'agreement',
    name: 'Agreement',
    component: () => import('@/views/base-business/user-agreement/Agreement'),
    meta: {
      header: false
    }
  },
  {
    path: 'privacy',
    name: 'Privacy',
    component: () => import('@/views/base-business/user-agreement/Privacy'),
    meta: {
      header: false
    }
  },
  {
    path: 'login',
    name: 'Login',
    component: Login,
    meta: {
      header: false
    }
  },
  {
    path: 'gtmLogin',
    name: 'GtmLogin',
    component: () => import('@/views/base-business/gtm-login/GtmLogin'),
    meta: {
      header: false
    }
  },
  {
    path: 'notFound',
    name: 'NotFound',
    component: () => import('@/views/404.vue'),
    meta: {
      header: false
    }
  },
  {
    path: 'redirection',
    name: 'Redirection',
    component: () => import('@/views/Redirection.vue'),
    meta: {
      header: true
    }
  },
  {
    path: 'userRole',
    name: 'UserRole',
    component: () => import('@/views/UserRole.vue'),
    meta: {
      header: true
    }
  }
]
