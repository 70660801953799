const backendMenu = [
  {
    name: '金融产品管理页面',
    routeName: 'ProductMgt',
    path: '/productMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '金融机构管理页面',
    routeName: 'MerchantMgt',
    path: '/merchantMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '金融机构管理详情页面',
    routeName: 'BackendMerchantDetail',
    path: '/backendMerchantDetail',
    activePath: 'backend/merchantMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '租户管理页面',
    routeName: 'TenantMgt',
    path: '/tenantMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '租户管理详情页面',
    routeName: 'TenantDetail',
    path: '/tenantDetail',
    activePath: 'backend/tenantMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '平台管理页面',
    routeName: 'BackendPlatformMgt',
    path: '/backendPlatformMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '平台管理详情页面',
    routeName: 'BackendPlatformDetail',
    path: '/backendPlatformDetail',
    activePath: 'backend/backendPlatformMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  },
  {
    name: '平台管理详情页面',
    routeName: 'BackendPlatformMerchantDetail',
    path: '/backendPlatformMerchantDetail',
    activePath: 'backend/backendPlatformMgt',
    appName: 'backend',
    auth: true,
    aside: true,
    header: true,
    headerMenu: false
  }
]

export { backendMenu }
