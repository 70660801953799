import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 提交留资信息
   * @param params
   * @param data
   * @returns {*}
   */
  fetchVisitorSubmit (params, data) {
    return axios.post(`${BASE_APP_PATH}/visitor/submit`, data, { params }).then(res => res.data)
  }
}
