import { Container, Header, Aside, Main } from 'element-gui'

import './style/index.scss'
import GTMLayout from './GTMLayout'
import GTMHeader from './GTMHeader'
import GTMAside from './GTMAside'
import GTMContent from './GTMContent'

GTMLayout.install = function (Vue) {
  Vue.use(Container)
  Vue.component(GTMLayout.name, GTMLayout)
}
GTMHeader.install = function (Vue) {
  Vue.use(Header)
  Vue.component(GTMHeader.name, GTMHeader)
}
GTMAside.install = function (Vue) {
  Vue.use(Aside)
  Vue.component(GTMAside.name, GTMAside)
}
GTMContent.install = function (Vue) {
  Vue.use(Main)
  Vue.component(GTMContent.name, GTMContent)
}

export default {
  GTMLayout,
  GTMHeader,
  GTMAside,
  GTMContent
}
