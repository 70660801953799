import axios from 'axios'
import { DEVELOPMENT_PATH } from '../base-url'

export default {
  /**
   * 通过productInfo获取版本信息
   * @param productInfo
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetProductVersionByProductInfo
   */
  fetchGetProductVersionByProductInfo (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/versions`, data, { params })
      .then(res => res.data)
  },
  /**
   * 通过versionId获取接口配置信息
   * @param versionId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetInterfaceConfigByVersionId
   */
  fetchGetInterfaceConfigByVersionId (versionId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/product/interfaces/list/${versionId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过versionId获取接口配置信息
   * @param versionId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetTradingConfigByVersionId
   */
  fetchGetTradingConfigByVersionId (versionId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/product/transactionInterfaces/list/${versionId}`, { params })
      .then(res => res.data)
  },
  /**
   * 保存接口信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchUpdateInterfaceDetail
   */
  fetchUpdateInterfaceDetail (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/interfaces`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存交易场景信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchUpdateTradingInterfaceDetail
   */
  fetchUpdateTradingInterfaceDetail (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/transactionInterfaces`, data, { params })
      .then(res => res.data)
  },
  /**
   * 通过interfaceId删除某条接口信息
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchDeleteInterfaceDetailInfoByInterfaceId
   */
  fetchDeleteInterfaceDetailInfoByInterfaceId (interfaceId, params) {
    return axios.delete(`${DEVELOPMENT_PATH}/product/interfaces/${interfaceId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过interfaceId删除某条交易场景信息
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchDeleteTradingInterfaceDetailInfoByInterfaceId
   */
  fetchDeleteTradingInterfaceDetailInfoByInterfaceId (interfaceId, params) {
    return axios.delete(`${DEVELOPMENT_PATH}/product/transactionInterfaces/${interfaceId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过interfaceId获取接口参数详情信息，以此进行编辑
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetParametersInfoByInterfaceId
   */
  fetchGetParametersInfoByInterfaceId (interfaceId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/product/parameters/${interfaceId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过interfaceId获取交易接口参数详情信息，以此进行编辑
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetTradingStepsInfoByInterfaceId
   */
  fetchGetTradingStepsInfoByInterfaceId (interfaceId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/product/steps/${interfaceId}`, { params })
      .then(res => res.data)
  },
  /**
   * 保存接口里的参数信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchUpdateParametersDetail
   */
  fetchUpdateParametersDetail (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/parameters`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存交易场景步骤里的参数信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchUpdateTradingStepsDetail
   */
  fetchUpdateTradingStepsDetail (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/steps`, data, { params })
      .then(res => res.data)
  },
  /**
   * 通过interfaceId获取接口参场景详情信息，以此进行编辑
   * @param interfaceId
   * @param params
   * @returns {*} $developmentApi.PRODUCT.fetchGetScenesInfoByInterfaceId
   */
  fetchGetScenesInfoByInterfaceId (interfaceId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/product/scenes/${interfaceId}`, { params })
      .then(res => res.data)
  },
  /**
   * 保存接口里的场景信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.PRODUCT.fetchUpdateScenesDetail
   */
  fetchUpdateScenesDetail (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/product/scenesSave`, data, { params })
      .then(res => res.data)
  }
}
