import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 平台信信息列表
   * @param params
   * @returns {*} $sysappApi.PLATFORM.fetchGetPlatformList
   */
  fetchGetPlatformList (params, data) {
    return axios.post(`${SYS_APP_PATH}/platform/list`, data, { params }).then(res => res.data)
  },
  /**
   * 保存平台信息
   * @param params
   * @returns {*} $sysappApi.PLATFORM.fetchAddPlatform
   */
  fetchAddPlatform (params, data) {
    return axios.post(`${SYS_APP_PATH}/platform/save`, data, { params }).then(res => res.data)
  },
  /**
   * 删除平台
   * @param params
   * @returns {*} $sysappApi.PLATFORM.fetchDeletePlatform
   */
  fetchDeletePlatform (params, data) {
    return axios.post(`${SYS_APP_PATH}/platform/delete`, data, { params }).then(res => res.data)
  }
}
