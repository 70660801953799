const clearLocalInfo = () => {
  const appName = localStorage.getItem('portal_root_appName')
  localStorage.clear()
  sessionStorage.clear()
  sessionStorage.setItem('portal_root_appName', appName)
  localStorage.setItem('portal_root_appName', appName)
  document.cookie = 'G3_SESSION_V=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;'
  document.cookie = 'SESSION_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;'
}

/**
 * 父应用跳转子应用
 * @param appName
 * @param path
 * @param router
 */
const microRoutePush = (appName, path, router) => {

}

const qiankunRouterPush = ({ appName, path, query }) => {
  window.parent.postMessage(
    {
      type: 'router',
      action: 'routerPush',
      appName,
      path,
      query
    },
    '*'
  )
}
// 新开页方法
const qiankunRouterResolve = ({ appName, path, query }) => {
  window.parent.postMessage(
    {
      type: 'router',
      action: 'routerResolve',
      appName,
      path,
      query
    },
    '*'
  )
}

// 埋点
const getTrackUserInfo = info => {
  let userInfo =
    info.aecloudUserId + '%' + info.loginName + '%' + info.enterpriseName + '%' + info.enterpriseCode + '%' + info.tel
  return userInfo
}

export { clearLocalInfo, microRoutePush, getTrackUserInfo }

export default {
  clearLocalInfo,
  microRoutePush,
  getTrackUserInfo,
  qiankunRouterPush,
  qiankunRouterResolve
}
