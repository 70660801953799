import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-gui'

Vue.prototype.$axios = axios
axios.defaults.timeout = 60 * 1000 // 毫秒数                       //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8' // 配置请求头
// 请求后端的时候携带cookie
axios.defaults.withCredentials = true
const initAxiosInterceptor = function () {
  axios.interceptors.response.use(
    response => {
      if (response && response.data) {
        let data = response.data
        if (Object.prototype.hasOwnProperty.call(data, 'code')) {
          if (data.code !== 0) {
            let warningMsg = response.data.message || response.data.msg
            if (data.code === 500 && response.data.msg === '尚未登录，请先登录') {
              return response
            } else if (warningMsg) {
              Message.warning(warningMsg)
              return response
            } else {
              return response
            }
          } else {
            return response
          }
        } else {
          // 其他产品接口
          return response
        }
      }
    },
    error => {
      if (error.response) {
        switch (error.response.status) {
        case 500:
          Message.error('服务器出现错误，请稍后再试')
          break
        case 401:
          window.parent.postMessage(
            {
              type: 'router',
              action: 'interfaceNoAuth'
            },
            '*'
          )
          break
        }
      }
      // if (error.message.includes('Network Error')) {
      //   Message.error('您的网络开小差了')
      // }
      return Promise.reject(error)
    }
  )
}
export default {
  initAxiosInterceptor
}
