import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 通过applyId获取接口配置信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INTERFACE.fetchGetInterfaceConfigByApplyId
   */
  fetchGetInterfaceConfigByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/interface/interfaceConfig/find/${applyId}`, { params }).then(res => res.data)
  },
  /**
   * 通过applyId获取业务配置信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INTERFACE.fetchGetBusinessConfigByApplyId
   */
  fetchGetBusinessConfigByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/interface/businessConfig/find/${applyId}`, { params }).then(res => res.data)
  },
  /**
   * 提交接口配置信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.INTERFACE.fetchUpdateInterfaceConfig
   */
  fetchUpdateInterfaceConfig (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/interface/interfaceConfig/save`, data, { params }).then(res => res.data)
  },
  /**
   * 提交业务配置信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.INTERFACE.fetchUpdateBusinessConfig
   */
  fetchUpdateBusinessConfig (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/interface/businessConfig/save`, data, { params }).then(res => res.data)
  },
  /**
   * 通过productId获取接口说明信息
   * @param params
   * @param data
   * @returns {*} $developmentApi.INTERFACE.fetchGetInterfaceDesc
   */
  fetchGetInterfaceDesc (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/interface/illustrate`, data, { params }).then(res => res.data)
  }
}
