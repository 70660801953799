import axios from 'axios'
import { SYS_APP_PATH } from '../base-url'

export default {
  /**
   * 查询租户下发产品
   * @param params
   * @returns {*} $sysappApi.ISSUED.fetchGetIssuedProduct
   */
  fetchGetIssuedProduct (params) {
    return axios.get(`${SYS_APP_PATH}/issued/product`, { params }).then(res => res.data)
  },
  /**
   * 查询租户下发的机构列表
   * @param params
   * @returns {*} $sysappApi.ISSUED.fetchGetIssuedMerchant
   */
  fetchGetIssuedMerchant (params) {
    return axios.get(`${SYS_APP_PATH}/issued/merchant`, { params }).then(res => res.data)
  },
  /**
   * 新增租户的下发机构
   * @param params
   * @returns {*} $sysappApi.ISSUED.fetchAddIssuedMerchant
   */
  fetchAddIssuedMerchant (params, data) {
    return axios.post(`${SYS_APP_PATH}/issued/merchant/add`, data, { params }).then(res => res.data)
  },
  /**
   * 删除租户的下发机构
   * @param params
   * @returns {*} $sysappApi.ISSUED.fetchDeleteIssuedMerchant
   */
  fetchDeleteIssuedMerchant (params, data) {
    return axios.post(`${SYS_APP_PATH}/issued/merchant/del`, data, { params }).then(res => res.data)
  }
}
