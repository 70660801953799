import axios from 'axios'
import { SYS_APP_PATH } from '../../base-url'

export default {
  /**
   * 机构列表
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchGetMerchantTemplateList
   */
  fetchGetMerchantTemplateList (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/template/list`, data, { params }).then(res => res.data)
  },
  /**
   * 创建机构
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchAddMerchantTemplate
   */
  fetchAddMerchantTemplate (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/template/create`, data, { params }).then(res => res.data)
  },
  /**
   * 通过机构编码查询机构信息
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchMerchantTemplateByMerchantCode
   */
  fetchMerchantTemplateByMerchantCode (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/template/find`, data, { params }).then(res => res.data)
  },
  /**
   * 通过机构编码查询机构信息
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchUpdateMerchantTemplate
   */
  fetchUpdateMerchantTemplate (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/template/update`, data, { params }).then(res => res.data)
  },
  /**
   * 删除机构
   * @param params
   * @returns {*} $sysappApi.MERCHANT.fetchDeleteMerchantTemplate
   */
  fetchDeleteMerchantTemplate (params, data) {
    return axios.post(`${SYS_APP_PATH}/merchant/template/delete`, data, { params }).then(res => res.data)
  }
}
