const guaranteeMenu = [
  {
    name: '机构选择',
    routeName: 'InstitutionList',
    isActive: false,
    path: '/institutionList',
    appName: 'guarantee-web',
    auth: false,
    aside: false,
    header: true
  },
  {
    name: '保函申请父组件路由',
    routeName: 'Apply',
    isActive: false,
    path: '/apply',
    activePath: 'guarantee-web/institutionList',
    appName: 'guarantee-web',
    auth: true,
    aside: false,
    header: true
  },
  {
    name: '保函预览',
    routeName: 'GuaranteeView',
    isActive: false,
    path: '/guaranteeView',
    appName: 'guarantee-web',
    auth: false,
    aside: false,
    header: false
  },
  {
    name: '实名认证',
    routeName: 'RealNameAuth',
    isActive: false,
    path: '/realNameAuth',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '个人认证',
    routeName: 'UserAuth',
    isActive: false,
    path: '/userAuth',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '企业认证',
    routeName: 'EnterpriseAuth',
    isActive: false,
    path: '/enterpriseAuth',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '我的信息',
    routeName: 'UserInfo',
    isActive: false,
    path: '/userInfo',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '用户订单',
    routeName: 'UserOrderList',
    isActive: false,
    path: '/userOrderList',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '我的受益保函',
    routeName: 'UserGuaranteeList',
    isActive: false,
    path: '/userGuaranteeList',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '项目列表',
    routeName: 'ProjectList',
    isActive: false,
    path: '/projectList',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  },
  {
    name: '项目录入',
    routeName: 'ProjectAdd',
    isActive: false,
    path: '/projectAdd',
    appName: 'guarantee-web',
    auth: true,
    aside: true,
    header: true
  }
]
export { guaranteeMenu }
