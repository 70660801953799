<template>
  <div class="gtm-micro-app__page">
    <div class="gtm-micro-app__backend" id="backend"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { loadMicroApp } from 'qiankun'

export default {
  name: 'Backend',
  props: {},
  data () {
    return {
      microApp: null,
      microAppRender: false
    }
  },
  computed: {
    ...mapState(['rootMicroAppData', 'staticFixedPath'])
  },
  mounted () {
    if (this.$store.state.activeMicroApp.instance && this.$store.state.activeMicroApp.appName === 'backend') {
      this.$store.state.activeMicroApp.instance.unmount()
    }
    let script = document.createElement('script')
    script.id = 'tinymceJs'
    script.src = `${this.staticFixedPath}/tinymce/tinymce.min.js`
    document.head.appendChild(script)
    script.onload = () => {
      this.microApp = loadMicroApp({
        name: 'backend',
        entry: `${window.location.origin}/backend`,
        container: '#backend',
        activeRule: '/gtm/web/platform/backend',
        props: { data: this.rootMicroAppData }
      })
      this.$store.state.activeMicroApp = {
        instance: this.microApp,
        appName: 'backend'
      }
      console.log('【base-platform】【backend】子应用执行mounted方法')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.microApp !== null) {
      let tinymceJS = document.getElementById('tinymceJS')
      if (tinymceJS) {
        tinymceJS.parentNode.removeChild(tinymceJS)
      }
      this.microApp.unmount()
    }
    next()
  },
  methods: {}
}
</script>

<style lang="scss">
.gtm-micro-app__page {
  width: 100%;
  height: 100%;

  .gtm-micro-app__backend {
    height: 100%;
  }

  #__qiankun_microapp_wrapper_for_backend__ {
    height: 100%;
  }
}
</style>
