import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 发送短信验证码
   * @param params
   * @returns {*} USER.fetchUserSendSmsCode
   */
  fetchUserSendSmsCode (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/sendSmsCode`, data, { params })
      .then(res => res.data)
  },
  /**
   * 用户注册
   * @param params
   * @returns {*} USER.fetchUserRegister
   */
  fetchUserRegister (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/register`, data, { params })
      .then(res => {
        console.log(res)
        return res.data
      })
  },
  /**
   * 用户登录
   * @param params
   * @returns {*} USER.fetchUserLogin
   */
  fetchUserLogin (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/login`, data, { params })
      .then(res => res.data)
  },
  /**
   * 判断是否已经选过角色
   * @param params
   * @returns {*} USER.fetchUserHasSelectedRole
   */
  fetchUserHasSelectedRole (params) {
    return axios.get(`${BASE_APP_PATH}/user/hasSelectedRole`, { params })
      .then(res => res.data)
  },
  /**
   * 选择角色
   * @param params
   * @returns {*} USER.fetchUserSelectRole
   */
  fetchUserSelectRole (params, data) {
    return axios.post(`${BASE_APP_PATH}/user/selectRole`, data, { params })
      .then(res => res.data)
  },
  /**
   * 获取租户原始域名？
   * @param params
   * @returns {*} USER.fetchGetUserTenant
   */
  fetchGetUserTenant (params) {
    return axios.get(`${BASE_APP_PATH}/user/tenant`, { params })
      .then(res => res.data)
  }
}
