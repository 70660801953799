import axios from 'axios'
import { PROJECT_APP_PATH } from '../base-url'

export default {
  /**
   * 业务侧项目检索列表
   * @param params
   * @returns {*} PROJECT.fetchGetProjectList
   */
  fetchGetProjectList (params, data) {
    return axios.post(`${PROJECT_APP_PATH}/project/search`, data, { params })
      .then(res => res.data)
  },
  /**
   * 招标代理录入后的项目列表
   * @param params
   * @returns {*} PROJECT.fetchGetBiddingProjectList
   */
  fetchGetBiddingProjectList (params, data) {
    return axios.post(`${PROJECT_APP_PATH}/project/list`, data, { params })
      .then(res => res.data)
  },
  /**
   * 发布项目信息
   * @param params
   * @returns {*} PROJECT.fetchPublishProjectInfo
   */
  fetchPublishProjectInfo (params, data) {
    return axios.post(`${PROJECT_APP_PATH}/project/publish`, data, { params })
      .then(res => res.data)
  },
  /**
   * 保存项目信息
   * @param params
   * @returns {*} PROJECT.fetchUpdateProjectInfo
   */
  fetchUpdateProjectInfo (params, data) {
    return axios.post(`${PROJECT_APP_PATH}/project/save`, data, { params })
      .then(res => res.data)
  },
  /**
   * 校验项目信息
   * @param params
   * @returns {*} PROJECT.fetchCheckProjectInfo
   */
  fetchCheckProjectInfo (params, data) {
    return axios.post(`${PROJECT_APP_PATH}/project/check`, data, { params })
      .then(res => res.data)
  },
  /**
   * 通过项目id获取项目详情
   * @param params
   * @returns {*} PROJECT.fetchGetProjectInfoById
   */
  fetchGetProjectInfoById (projectNo, params) {
    return axios.get(`${PROJECT_APP_PATH}/project/find/${projectNo}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过项目Id删除某个项目
   * @param params
   * @returns {*} PROJECT.fetchDeleteProjectInfoById
   */
  fetchDeleteProjectInfoById (id) {
    return axios.delete(`${PROJECT_APP_PATH}/project/delete/${id}`)
      .then(res => res.data)
  },
  /**
   * 通过交易平台编码，项目编号和标段编号获取项目信息
   * @param params
   * @returns {*}
   */
  fetchGetProjectInfo (params) {
    return axios.get(`${PROJECT_APP_PATH}/project/getProjectInfo`, { params })
      .then(res => res.data)
  }

}
