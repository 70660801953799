<template>
  <el-main class="gtm-business-content">
    <slot></slot>
  </el-main>
</template>

<script>
export default {
  name: 'GtmBusinessContent'
}
</script>
