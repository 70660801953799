<template>
  <div id="unsupportedBrowserIframe">
    <img class="browser-background-img" src="../assets/images/browser-transition/browserBackImg.png">
    <img style="height: 80px; width: 80px;" src="../assets/images/browser-transition/warning.png" alt="">
    <div class="browser-title">你正在使用的浏览器版本过低，将不能正常浏览和使用金服平台</div>
    <p class="browser-text">建议复制链接后，使用以下浏览器打开</p>
    <img class="browser-img" src="../assets/images/browser-transition/browser.png" alt="">
    <el-button id="copy-button" @click="copyLinkToClipboard()">复制链接</el-button>
    <input type="text" id="hiddenInput" style="position: absolute; left: -9999px">
  </div>
</template>

<script>

export default {
  name: 'BrowserTransition',
  components: {},
  data () {
    return {}
  },
  methods: {
    copyLinkToClipboard () {
      // 获取隐藏的文本输入框
      const hiddenInput = document.getElementById('hiddenInput')
      // 定义要复制的文本
      const textToCopy = window.location.href
      // 将文本设置为隐藏输入框的值
      hiddenInput.value = textToCopy
      // 选择文本
      hiddenInput.select()
      // 复制文本
      document.execCommand('copy')
    }
  }
}
</script>

<style lang="scss">
/* 为 iframe 添加样式 */
#unsupportedBrowserIframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #313131;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.browser-background-img{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: calc(520 / 1920 * 100vw);
  z-index: -1;
  /* width: 99.6vw; */
}
.browser-title{
  margin-top: 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
}
.browser-text{
  margin-top: 20px;
  font-size: 24px;
  line-height: 31px;
}
.browser-img{
  margin-top: 50px;
  width: 718px;
  height: 110px;
}
#copy-button{
  margin-top: 70px;
  height: 56px;
  width: 156px;
  background: linear-gradient( 135deg, #3196FF 0%, #165DFF 100%);
  border-radius: 28px;
  border: none;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 24px;
}
#copy-button:hover{
  cursor: pointer;
  background: linear-gradient(135deg, rgba(49, 150, 255, 0.8) 0%, rgba(22, 93, 255, 0.8) 100%);
}
#copy-button:active{
  background: #1F47C8;
}
</style>
