/* eslint-disable */
import guaranteeApi from './guarantee'
import developmentApi from './development'
import sysappApi from './sysapp'
import baseAppApi from './base-app/index'
import projectAppApi from './project-app'
import dataAppApi from './data-app'

export { guaranteeApi, developmentApi, sysappApi, baseAppApi, projectAppApi }

export default {
  install(Vue) {
    Vue.prototype.$guaranteeApi = guaranteeApi
    Vue.prototype.$developmentApi = developmentApi
    Vue.prototype.$sysappApi = sysappApi
    Vue.prototype.$baseAppApi = baseAppApi
    Vue.prototype.$projectAppApi = projectAppApi
    Vue.prototype.$dataAppApi = dataAppApi
  }
}
