import Bignumber from 'bignumber.js'

export const bigNumberFormat = (num, len = 2) => {
  if (num === null) {
    return '--'
  }
  // 格式化金额
  return new Bignumber(num).toFormat(len)
}
export const bigNumberToFixed = (num, len = 2) => {
  if (num === null) {
    return '--'
  }
  // 格式化金额
  return new Bignumber(num).toFixed(len)
}
// 大数据计算求和
export const bigNumberAdd = (arg1 = 0, arg2 = 0) => {
  return new Bignumber(arg1).plus(arg2).toString()
}
export const bigNumberMul = (arg1 = 0, arg2 = 0) => {
  return new Bignumber(arg1).multipliedBy(arg2).toString()
}
