<template>
  <div>
    <!--  对接交易底部  -->
    <el-footer v-if="isTrade" class="gtm-trade-footer">
      <div class="gtm-trade-footer-inner">
        <trade-footer></trade-footer>
      </div>
    </el-footer>
    <!--  常规底部  -->
    <el-footer v-else class="gtm-business-footer">
      <div class="gtm-business-foot-inner">
        <footer-area></footer-area>
      </div>
    </el-footer>
  </div>
</template>

<script>
import FooterArea from './components/Footer.vue'
import TradeFooter from './components/TradeFooter'

export default {
  name: 'GtmBusinessFooter',
  components: { FooterArea, TradeFooter },
  data () {
    return {
      isTrade: false,
      tenantConfig: JSON.parse(sessionStorage.getItem('portal_root_tenantConfig'))
    }
  },
  mounted () {
    if (this.tenantConfig.host) {
      this.isTrade = true
    } else {
      this.isTrade = false
    }
  }
}
</script>
<style lang="scss">
</style>
