import axios from 'axios'
import { GUARANTEE_APP_PATH } from '../base-url'

export default {
  /**
   * 通过机构编码查询页面流
   * @param params
   * @returns {*} $guaranteeApi.PAGE_FLOW.fetchPageFlowSearch
   */
  fetchPageFlowSearch (params, data) {
    return axios.post(`${GUARANTEE_APP_PATH}/pageFlow/search`, data, { params }).then(res => res.data)
  }
}
