<template>
  <div class="gtm-empty">
    <div class="empty-image"></div>
    <div class="empty-text">{{ emptyDescription }}</div>
  </div>
</template>

<script>
export default {
  name: 'GtmEmpty',
  components: {},
  mixins: [],
  props: {
    emptyText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    emptyDescription () {
      return this.emptyText
    }
  },
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style lang="scss">
.gtm-empty {
  height: 327px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-image {
    background: url(../../assets/image/gtmEmpty/empty.png) center center no-repeat;
    background-size: cover;
    height: 162px;
    width: 172px;
  }

  .empty-text {
    margin-top: 15px;
    color: #666666;
  }
}
</style>
