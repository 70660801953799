/**
 * 公共上下文
 */
import rootState from '../../root-state'
import { guaranteeApi, sysappApi } from '@gtm-api'
import { queryToParams } from 'web-utils'

const requestIndexData = () => {
  const params = {
    time: new Date() * 1
  }
  return guaranteeApi.COMMON.fetchIndexData(params)
    .then(res => {
      if (res.code === 500) {
        rootState.loginStatus = false
        rootState.indexInfo = res
      } else {
        rootState.loginStatus = true
        rootState.indexInfo = res
      }
    })
}

const requestUserInfo = () => {
  const body = {
    time: new Date() * 1
  }
  return guaranteeApi.USER.fetchUserViewList({}, body)
    .then(res => {
      rootState.userInfo = res?.data || {}
      const portalRootUserLoginType = sessionStorage.getItem('portal_root_userLoginType')
      if (!portalRootUserLoginType) {
        if (rootState.userInfo.dataFromType === 'TRADE') {
          sessionStorage.setItem('portal_root_userLoginType', 'GTM_SAAS_TRADE')
        } else {
          sessionStorage.setItem('portal_root_userLoginType', 'GTM_SAAS_SSO')
        }
      }
    })
    .finally(res => {
      return res
    })
}

const requestBusinessProduct = () => {
  const params = {
    platformCode: ''
  }
  return sysappApi.TENANT.fetchGetTenantBusinessProduct(params)
    .then(res => {
      let data = res?.data || []
      rootState.productList = data.map(item => {
        return {
          productCode: item.productCode,
          productName: item.productName
        }
      })
    })
    .finally(res => {
      return res
    })
}

const initAppContext = async type => {
  console.log('【app-context】公共上下文启动...', sessionStorage.getItem('portal_root_projectCode'))
  await requestIndexData()
  if (type !== 'noProduct') {
    await requestBusinessProduct()
  }
  if (rootState.loginStatus === true) {
    await requestUserInfo()
    const { projectCode, sectionCode } = queryToParams()
    const realProjectCode = projectCode || sessionStorage.getItem('portal_root_projectCode')
    const realSectionCode = sectionCode || sessionStorage.getItem('portal_root_sectionCode')
    if (realProjectCode && realSectionCode) {
      let params = {
        projectCode: realProjectCode,
        sectionCode: realSectionCode,
        platformCode: rootState.userInfo.platformCode
      }
      await guaranteeApi.GUARANTEE_APPLY.fetchGetTradeInfo(params)
        .then(res => {
          rootState.tradeProjectInfo = res.data || {}
        })
    }
  }
  console.log(rootState, rootState)
  for (const key in rootState) {
    setLocalValue(`portal_root_${key}`, JSON.stringify(rootState[key]))
  }
  console.log('【app-context】公共上下文加载完成，rootState:', rootState)
}

const setLocalValue = (key, value) => {
  sessionStorage.setItem(key, value)
  localStorage.setItem(key, value)
}

export default {
  initAppContext,
  requestIndexData,
  requestUserInfo,
  setLocalValue
}

export { initAppContext, requestIndexData, requestUserInfo, setLocalValue }
