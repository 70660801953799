import axios from 'axios'
import { BASE_APP_PATH } from '../base-url'

export default {
  /**
   * 上传文件
   * @param params
   * @returns {*} FILE.fetchFileUpload
   */
  fetchFileUpload (params, data) {
    return axios.post(`${BASE_APP_PATH}/file/upload`, data, { params }).then(res => res.data)
  },
  /**
   * 上传补充文件
   * @param params
   * @returns {*} FILE.fetchFileUploadExt
   */
  fetchFileUploadExt (params, data) {
    return axios.post(`${BASE_APP_PATH}/file/uploadExt`, params, data).then(res => res.data)
  },
  /**
   * 删除附件
   * @param params
   * @param data
   * @returns {*} FILE.fetchDeleteFileUpload
   */
  fetchDeleteFileUpload (params, data) {
    return axios.post(`${BASE_APP_PATH}/file/delete`, data, { params }).then(res => res.data)
  },
  /**
   * 获取附件类型
   * @param params
   * @param data
   * @returns {*} FILE.fetchFileQueryAttachTypes
   */
  fetchFileQueryAttachTypes (params, data) {
    return axios.post(`${BASE_APP_PATH}/file/queryAttachTypes`, data, { params }).then(res => res.data)
  },
  /**
   * 附件下载
   * @param params
   * @returns {*} FILE.fetchFileDownload
   */
  fetchFileDownload (params) {
    return axios.get(`${BASE_APP_PATH}/file/download`, { params }).then(res => res.data)
  }
}
