import axios from 'axios'
import { DEVELOPMENT_PATH } from '../../base-url'

export default {
  /**
   * 通过applyId获取接口测试信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INTERFACE.fetchGetInterfaceTestListByApplyId
   */
  fetchGetInterfaceTestListByApplyId (applyId, params) {
    return axios
      .get(`${DEVELOPMENT_PATH}/interfaceScene/getInterfaceTestScenes/${applyId}`, { params })
      .then(res => res.data)
  },
  /**
   * 通过applyId获取机构测试信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INTERFACE.fetchGetTestInstitutionByApplyId
   */
  fetchGetTestInstitutionByApplyId (applyId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/interfaceScene/findInstanceInfo/${applyId}`, { params }).then(res => res.data)
  },
  /**
   * 通过sceneId获取接口测试场景参数信息
   * @param applyId
   * @param params
   * @returns {*} $developmentApi.INTERFACE.fetchGetInterfaceTestParamsBySceneId
   */
  fetchGetInterfaceTestParamsBySceneId (sceneId, params) {
    return axios.get(`${DEVELOPMENT_PATH}/interfaceScene/editScene/${sceneId}`, { params }).then(res => res.data)
  },
  /**
   * 运行接口测试参数
   * @param params
   * @param data
   * @returns {*} $developmentApi.INTERFACE.fetchUpdateInterfaceTestParams
   */
  fetchUpdateInterfaceTestParams (params, data) {
    return axios.post(`${DEVELOPMENT_PATH}/interfaceScene/runTestScene`, data, { params }).then(res => res.data)
  },
  /**
   * 删除用例
   * @param sceneId
   * @returns {*} $developmentApi.INTERFACE.fetchDeleteInterfaceSceneBySceneId
   */
  fetchDeleteInterfaceSceneBySceneId (sceneId) {
    return axios.post(`${DEVELOPMENT_PATH}/interfaceScene/delete/${sceneId}`).then(res => res.data)
  }
}
