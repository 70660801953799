<template>
  <div id="app">
    <browser-transition v-if="isIE"></browser-transition>
    <router-view v-else></router-view>
  </div>
</template>

<script>

import BrowserTransition from '@/components/BrowserTransition'
export default {
  name: 'App',
  components: { BrowserTransition },
  data () {
    return {
      isIE: ''
    }
  },
  computed: {},
  watch: {},
  methods: {},
  created () {
    // 检测浏览器类型
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
    if (this.isIE) {
      // 提取域名
      const hostname = window.location.hostname
      // 根据域名设置不同的标题
      if (hostname === 'neimeng-gyr.glodon.com') {
        document.title = '内蒙古工程建设金融服务平台'
      } else if (hostname === 'tongch-gyr.glodon.com') {
        document.title = '铜川市公共资源交易金融服务平台'
      } else if (hostname === '219.142.102.242') {
        document.title = '铜川市公共资源交易金融服务平台'
      }
      document.getElementById('nprogress').style.display = 'none'
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.gtm-micro-app__content {
  height: 100%;
  overflow-y: auto;
}
</style>
